import React, { useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import { useAuth } from 'lib/auth'
import { PageHeader } from 'components/page-header'
import BookingTypesView from './booking-types-view'
import useStyles from './styles'
import SocialShareDialog from 'components/social-share-dialog'

const BookingTypesPage = () => {
  const classes = useStyles()
  const { path } = useRouteMatch()
  const { user, setUserProperties } = useAuth()
  const history = useHistory()
  const [shouldShowDisclaimer, setShouldShowDisclaimer] = useState(false)

  const crumbs = [
    {
      label: 'Offerings',
      to: [...path.split('/').slice(0, 3), 'offerings'].join('/'),
    },
  ]

  const handleCreateBookingTypeClick = shouldShowDisclaimer => {
    if (shouldShowDisclaimer) return
    setUserProperties({ onboarding: false })
    history.push('/pga-coach/bookings/booking-types/new')
  }

  const actions = (
    <>
      <SocialShareDialog
        trigger={
          <Button variant="outlined" color="primary">
            Share Booking Link
          </Button>
        }
        dialogTitle="Share"
        shareUrl={user.coach.bookingUrl}
        shareTitle="My Offerings"
        bookingType=""
        showScheduleUrl={true}
        att="booking-link"
      />
      <Tooltip
        title={
          shouldShowDisclaimer
            ? 'To add another offering, you need to upgrade your PGA Coach account.'
            : ''
        }
      >
        <Button
          variant="contained"
          color="primary"
          data-cy="createBookingType"
          onClick={() => handleCreateBookingTypeClick(shouldShowDisclaimer)}
          className={shouldShowDisclaimer ? classes.disabledButton : ''}
        >
          Create Booking Type
        </Button>
      </Tooltip>
    </>
  )

  return (
    <>
      <PageHeader
        title="Booking Types"
        crumbs={crumbs}
        actions={actions}
        fixedMobileActions
      />
      <Box className={classes.mainWrapper}>
        <Grid container>
          <Grid item xs={12}>
            <BookingTypesView {...{ setShouldShowDisclaimer }} />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default BookingTypesPage
