import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  tabWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px',
    paddingBottom: '0px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      padding: 0,
    },
  },
  showCancellations: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '20px',
    },
  },
  containerStyles: {
    [theme.breakpoints.down('xs')]: {
      padding: '0',
      marginBottom: '60px',
    },
  },
  base: {
    background: '#e9f5fe',
    borderRadius: '10px',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: '0 16px',
    background: 'e9f5fe',
    color: 'e9f5fe',
  },
  logo: {
    width: '50px',
    minWidth: '50px',
    alignSelf: 'center',
  },
  body: {
    padding: '16px 16px',
  },
  button: {
    minWidth: '150px',
  },
  disabledButton: {
    background: '#0000001F',
    color: '#00000042',
    '&:hover': {
      background: '#0000001F',
    },
  },
}))

export default useStyles
