import React from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { PageHeader } from 'components/page-header'
import ApolloDataView from 'components/apollo-data-view'
import { useGetCoachAccount, useUpdateCoachAccount } from './api-hooks'
import { UnsavedChangesPrompt, FormActionBar } from 'components/form-util'
import { useAccountForm } from './use-account-form'
import { AccountForm } from './account-form'
import MembershipDetails from './membership-details'
import { useSnackbar } from 'notistack'
import SubscriptionDetails from './subscription-details'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
  },
}))

const AccountDetails = ({ coach, updateCoachAccount, updating }) => {
  const classes = useStyles()
  const { form } = useAccountForm(coach)
  const { enqueueSnackbar } = useSnackbar()

  const { handleSubmit, formState, errors } = form
  const hasErrors = Object.keys(errors).length > 0

  const handleUpdate = async ({ coachId, ...input }) => {
    try {
      const { data, error } = await updateCoachAccount({
        coachId,
        input: {
          ...input,
          phone: input.phone.replace(/-/g, ''),
        },
      })
      if (data?.success) {
        enqueueSnackbar('Update successful', { variant: 'success' })
        form.reset({ ...data.coach })
      } else {
        enqueueSnackbar(error?.message || 'Error updating account', {
          variant: 'error',
        })
      }
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  return (
    <>
      <FormActionBar
        instructions={hasErrors && 'Form has one or more errors'}
        error={hasErrors}
      >
        <Button
          color="primary"
          variant="contained"
          disabled={!formState.isDirty || updating}
          onClick={handleSubmit(handleUpdate)}
        >
          Save
        </Button>
      </FormActionBar>
      <SubscriptionDetails />
      <Box my={6}>
        <Container
          maxWidth="md"
          component={Paper}
          className={classes.container}
        >
          <AccountForm form={form} coach={coach} />
          <MembershipDetails />
        </Container>
      </Box>
      <UnsavedChangesPrompt hasChanges={formState.isDirty} />
    </>
  )
}

export const AccountDetailsWrapper = () => {
  const { data, loading, error } = useGetCoachAccount()

  const [updateCoachAccount, { loading: updating }] = useUpdateCoachAccount()
  const crumbs = [{ label: 'Account', to: '/account' }]

  return (
    <>
      <PageHeader title="Account Details" crumbs={crumbs} />
      <ApolloDataView {...{ data, error, loading }}>
        {{
          errorView: <div>Error Loading Coach Data</div>,
          emptyView: <div>Test Loading Coach Data</div>,
          loadingView: <LinearProgress color="primary" />,
          dataView: coach => (
            <AccountDetails
              coach={coach}
              updateCoachAccount={updateCoachAccount}
              updating={updating}
            />
          ),
        }}
      </ApolloDataView>
    </>
  )
}
