import React, { useEffect, useMemo } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { get } from 'lodash'
import qs from 'query-string'
import { GET_LESSON_CREDIT_BALANCE } from './queries'
import SelectLessonTime from './SelectLessonTime'
import SelectLessonType from './SelectLessonType'
import SelectStudent from './SelectStudent'
import ReviewBooking from './ReviewBooking'
import ReviewBookingLessonPacks from './ReviewBookingLessonPacks'
import BuyLessonPacksLayout from './BuyLessonPacksLayout'
import RedeemLessonsCredit from './RedeemLessonsCredit'
import { checkLessonCredits, checkLessonPack } from './utils'

const GET_LESSON_TYPE_BY_ID = gql`
  query GetLessonTypeById($id: ID) {
    lessonTypes(id: $id) {
      id
      lessonPacks {
        quantity
      }
    }
  }
`

const getCurrentUrl = () => {
  return window.location.pathname + window.location.search
}

const SchedulerStepController = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const { idOrSlug } = useParams()
  const location = useLocation()
  const {
    lessonTypeId,
    locationId,
    startDateTime,
    studentId,
    isLessonPacks,
    lessonPackId,
    lessonCreditBalanceQuantity,
    student,
  } = qs.parse(location.search)
  const currentUrl = getCurrentUrl()

  const { data } = useQuery(GET_LESSON_CREDIT_BALANCE)
  const { data: lessonType } = useQuery(GET_LESSON_TYPE_BY_ID, {
    skip: !lessonTypeId,
    variables: { id: lessonTypeId },
  })
  const isLessonPack = useMemo(() => checkLessonPack(lessonType), [lessonType])
  const lessonCreditBalances = useMemo(() => {
    if (!data) return null
    const regularCredits = get(
      data,
      'currentUser.attributes.lessonCreditBalances',
    )
    const giftedCredits = get(
      data,
      'currentUser.attributes.giftedLessonCreditBalances',
    )
    return [...(regularCredits || []), ...(giftedCredits || [])]
  }, [data])
  const hasLessonCredits = useMemo(
    () =>
      checkLessonCredits({
        lessonTypeId: lessonTypeId,
        lessonCreditBalances: lessonCreditBalances,
      }),
    [lessonCreditBalances, lessonTypeId],
  )

  const nextExpirationDate = useMemo(() => {
    if (!data) return null
    const regularCredits = get(
      data,
      'currentUser.attributes.lessonCreditBalances',
      [],
    )
    const giftedCredits = get(
      data,
      'currentUser.attributes.giftedLessonCreditBalances',
      [],
    )
    const allCredits = [...regularCredits, ...giftedCredits]
    const matchingCredits = allCredits.filter(
      credit => credit.lessonType.id === lessonTypeId,
    )
    if (!matchingCredits.length) return null
    const expirationDates = matchingCredits.map(
      credit => credit.nextExpirationDate,
    )

    if (!expirationDates.length) return null
    return expirationDates.reduce((earliest, current) =>
      current < earliest ? current : earliest,
    )
  }, [data, lessonTypeId])

  if (
    lessonTypeId &&
    locationId &&
    startDateTime &&
    studentId &&
    isLessonPack &&
    hasLessonCredits // Step to redeem Lesson Pack credits
  ) {
    const previousUrl = `/coach/${idOrSlug}/schedule?lessonTypeId=${lessonTypeId}&locationId=${locationId}&startDateTime=${startDateTime}&lessonCreditBalanceQuantity=${lessonCreditBalanceQuantity}`

    return (
      <RedeemLessonsCredit
        {...{
          idOrSlug,
          lessonTypeId,
          locationId,
          startDateTime,
          studentId,
          previousUrl,
          lessonCreditBalanceQuantity,
        }}
      />
    )
  }

  if (
    lessonTypeId &&
    locationId &&
    startDateTime &&
    studentId &&
    !isLessonPack &&
    hasLessonCredits // Step to redeem Lesson Pack credits
  ) {
    const previousUrl = `/coach/${idOrSlug}/schedule?lessonTypeId=${lessonTypeId}&locationId=${locationId}&startDateTime=${startDateTime}&lessonCreditBalanceQuantity=${lessonCreditBalanceQuantity}`

    return (
      <RedeemLessonsCredit
        {...{
          idOrSlug,
          lessonTypeId,
          locationId,
          startDateTime,
          studentId,
          previousUrl,
          lessonCreditBalanceQuantity,
        }}
      />
    )
  }

  if (
    lessonTypeId &&
    locationId &&
    startDateTime &&
    studentId &&
    !isLessonPack &&
    !hasLessonCredits
  ) {
    const previousUrl = student
      ? `/coach/${idOrSlug}/schedule?lessonTypeId=${lessonTypeId}&locationId=${locationId}&student=${student}`
      : `/coach/${idOrSlug}/schedule?lessonTypeId=${lessonTypeId}&locationId=${locationId}&startDateTime=${startDateTime}`
    return (
      <ReviewBooking
        {...{
          idOrSlug,
          lessonTypeId,
          locationId,
          startDateTime,
          studentId,
          previousUrl,
        }}
        progressValue={75}
        stepText="Step 3 of 3"
      />
    )
  }

  if (
    lessonTypeId &&
    locationId &&
    startDateTime &&
    studentId &&
    isLessonPack &&
    !hasLessonCredits
  ) {
    const previousUrl = student
      ? `/coach/${idOrSlug}/schedule?lessonTypeId=${lessonTypeId}&locationId=${locationId}&student=${student}`
      : `/coach/${idOrSlug}/schedule?lessonTypeId=${lessonTypeId}&locationId=${locationId}&startDateTime=${startDateTime}`

    return (
      <ReviewBookingLessonPacks
        idOrSlug={idOrSlug}
        lessonTypeId={lessonTypeId}
        locationId={locationId}
        startDateTime={startDateTime}
        studentId={studentId}
        previousUrl={previousUrl}
      />
    )
  }
  if (lessonTypeId && locationId && startDateTime) {
    const previousUrl = lessonCreditBalanceQuantity
      ? `/coach/${idOrSlug}/schedule?lessonTypeId=${lessonTypeId}&locationId=${locationId}&lessonCreditBalanceQuantity=${lessonCreditBalanceQuantity}`
      : `/coach/${idOrSlug}/schedule?lessonTypeId=${lessonTypeId}&locationId=${locationId}`

    return (
      <SelectStudent
        currentUrl={currentUrl}
        previousUrl={previousUrl}
        lessonTypeId={lessonTypeId}
        lessonCreditBalanceQuantity={lessonCreditBalanceQuantity}
        progressValue={50}
        stepText="Step 2 of 3"
      />
    )
  }
  if (lessonTypeId && locationId && isLessonPacks) {
    const previousUrl = `/coach/${idOrSlug}/schedule`
    return (
      <BuyLessonPacksLayout
        previousUrl={previousUrl}
        lessonTypeId={lessonTypeId}
        idOrSlug={idOrSlug}
        locationId={locationId}
        lessonPackId={lessonPackId}
      />
    )
  }
  if (lessonTypeId && locationId) {
    const studentQuery = student ? `?student=${student}` : ''
    const previousUrl = `/coach/${idOrSlug}/schedule${studentQuery}`
    return (
      <SelectLessonTime
        currentUrl={currentUrl}
        previousUrl={previousUrl}
        locationId={locationId}
        lessonTypeId={lessonTypeId}
        lessonCreditBalanceQuantity={lessonCreditBalanceQuantity}
        nextExpirationDate={nextExpirationDate}
      />
    )
  }
  if (idOrSlug) {
    return <SelectLessonType idOrSlug={idOrSlug} />
  }
  return null
}
export default SchedulerStepController
