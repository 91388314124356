import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import RouterLinkButton from 'components/router-link-button'

const CreatePgaHopeEventDialog = ({ open, setOpen }) => (
  <Dialog open={open} maxWidth="xs" onClose={() => setOpen(false)}>
    <DialogTitle>Create PGA HOPE Event</DialogTitle>
    <DialogContent>
      <Typography variant="body1" color="textSecondary">
        You have reached your group offering limit on the starter tier. As a
        certified PGA HOPE coach, you can only create events for PGA HOPE
        programing. For more event options, consider upgrading to PGA Coach+.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setOpen(false)} color="primary">
        Cancel
      </Button>
      <RouterLinkButton
        variant="contained"
        color="primary"
        to="/pga-coach/bookings/events/new"
      >
        Create Event
      </RouterLinkButton>
    </DialogActions>
  </Dialog>
)

export default CreatePgaHopeEventDialog
