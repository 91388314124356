import { gql } from '@apollo/client'
import GolferProfileFragment from 'fragments/student/golferProfile'
import GroupEventFragment from 'fragments/group-event'

const groupEventFragment = gql`
  fragment GroupEventFragment on GroupEvent {
    __typename
    ${GroupEventFragment}
    id
    startDateTime
    endDateTime
    timezone
    isCanceled
    title
    maxAttendance
    requiresAccessCode
    academyRequired
    academy {
      id
      name
      slug
    }
    isCanceled
    description
    revenueInCents
    seriesEndDateTime
    unlisted
    coachFacility {
      id
      name
    }
    coach {
      id
      name
    }
    enrollments {
      id
      createdAt
      student {
        id
        firstName
        lastName
        representatives {
          contact {
            externalId
            firstName
            lastName
            phone
            email
          }
        }
        pgaHopeEmergencyContact {
          id
          firstName
          lastName
          phone
          email
          relationship
        }
        demographicProfile {
          gender
        }
        pgaHopeProfile {
          id
          accommodations
          additionalInfo
          golfCareerInterest
          golfExperience
          handedness
          hasOwnClubs
          militaryStatus
          referralSource
          serviceBranch
          serviceYearStart
          serviceYearEnd
          shirtSize
        }
        golferProfile {
          ...GolferProfileAttributes
        }
      }
      isCanceled
    }
  }
`

const createGroupEventsQuery = queryName => gql`
  ${GolferProfileFragment}
  query ${queryName}(
    $orderBy: [GroupEventsOrderByInput!]
    $limit: Int
    $offset: Int
    $includeCanceled: Boolean
    $startDateTime: DateTimeRangeInput
  ) {
    currentUser {
      attributes {
        ... on Coach {
            freeTierLimitsReached
            canPromoteContent
        }
      }
      groupEvents(
        orderBy: $orderBy
        limit: $limit
        offset: $offset
        includeCanceled: $includeCanceled
        startDateTime: $startDateTime
      ) {
        ...GroupEventFragment
      }
    }
  }
  ${groupEventFragment}
`

const UPDATE_ENROLLMENT_ATTENDANCES = gql`
  mutation UpdateEnrollmentAttendances(
    $attendances: [EnrollmentAttendanceInput!]!
  ) {
    updateEnrollmentAttendances(attendances: $attendances) {
      success
      message
      enrollments {
        id
        attended
        student {
          id
          firstName
          lastName
        }
      }
    }
  }
`

const GET_UPCOMING_GROUP_EVENTS = createGroupEventsQuery(
  'GetUpcomingGroupEvents',
)
const GET_PAST_GROUP_EVENTS = createGroupEventsQuery('GetPastGroupEvents')

export {
  GET_UPCOMING_GROUP_EVENTS,
  GET_PAST_GROUP_EVENTS,
  UPDATE_ENROLLMENT_ATTENDANCES,
}
