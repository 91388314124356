import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { useStyles } from './styles'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import { YouTube } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import { Box } from '@material-ui/core'
import { XIcon } from 'components/x-share-button/x-icon'
import ButtonCta from 'components/atoms/button-cta'

const CoachMobileContactBanner = ({ coach, handleGetInTouchClick }) => {
  const coachFirstName = coach.name.split(' ')[0]
  const coachSocials = coach.coachProfile.socialJsonData
  const hasSocials = coachSocials?.length > 0
  const classes = useStyles({ hasSocials })
  const socialJsonData = [
    {
      type: 'IG',
      icon: <InstagramIcon fontSize="large" />,
    },
    {
      type: 'FB',
      icon: <FacebookIcon fontSize="large" />,
    },
    {
      type: 'YT',
      icon: <YouTube fontSize="large" />,
    },
    {
      type: 'TWT',
      icon: <XIcon fontSize="large" />,
    },
    {
      type: 'LI',
      icon: <LinkedInIcon fontSize="large" />,
    },
  ]
  const SocialIcon = ({ social }) => {
    const { type, reference } = social
    return (
      <IconButton
        href={reference}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.socialIcon}
      >
        {socialJsonData.find(social => social.type === type).icon}
      </IconButton>
    )
  }
  return (
    <Grid
      container
      className={classes.journeyContainer}
      justifyContent="center"
      data-testid="social-banner"
    >
      <Grid item className={classes.banner} container justifyContent="center">
        <Typography variant="h5" color="inherit">
          Begin Your Journey
        </Typography>
      </Grid>
      <Grid className={classes.coachContactsSection} container xs={10}>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          className={classes.workWithSection}
        >
          <Grid
            item
            container
            xs={12}
            display="flex"
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="h6"
              className={classes.title}
              children={`Work with ${coachFirstName}`}
            />
            <Typography
              variant="caption"
              className={classes.caption}
              align="center"
              children="Send a message regarding your goals."
            />
            <Box my={2}>
              <ButtonCta
                variant="contained"
                color="primary"
                data-testid="consolidated-banner-contact-button"
                href={`/coach/${coach.coachProfile.slug}/contact?source=mypga_schedule_contact_button`}
              >
                Contact {coachFirstName}
              </ButtonCta>
            </Box>
          </Grid>
        </Grid>
        {coachSocials && hasSocials && (
          <Grid item xs={12} container direction="column" alignItems="center">
            <Typography variant="h6" className={classes.title}>
              Social Media
            </Typography>
            <Typography variant="caption" className={classes.caption}>
              Follow us on social media
            </Typography>
            <Box className={classes.socialMediaSection}>
              {coachSocials.map((social, index) => {
                return <SocialIcon key={index} social={social} />
              })}
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default CoachMobileContactBanner
