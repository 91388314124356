import { gql, useQuery } from '@apollo/client'

const GET_STUDENTS = gql`
  query CoachStudents {
    coachStudents {
      id
      isMinor
      firstName
      lastName
      hasAccount
      representatives {
        relationshipType
        contact {
          externalId
          email
          phone
        }
      }
    }
  }
`

export function useGetStudents() {
  return useQuery(GET_STUDENTS, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  })
}

export const GET_LEADS = gql`
  query CoachingLeads {
    coachingInquiries {
      id
      createdAt
      viewedAt
      intent
      contact {
        id
        firstName
        lastName
        email
        phone
        externalId
        banned
      }
    }
    currentUser {
      attributes {
        ... on Coach {
          freeTierLimitsReached
        }
      }
    }
  }
`

const GET_LEAD = gql`
  query CoachingInquiryQuery($id: ID!) {
    coachingInquiry(id: $id) {
      id
      intent
      createdAt
      viewedAt
      contact {
        firstName
        lastName
        email
        phone
        phoneNumber
        externalId
        banned
      }
      coachProfile {
        id
      }
      questionnaire {
        question
        answer
      }
    }
  }
`

const UPCOMING_SESSIONS = gql`
  query(
    $startDateTime: DateTimeRangeInput!
    $orderBy: [ScheduleOrderByInput!]!
  ) {
    currentUser {
      schedule(startDateTime: $startDateTime, orderBy: $orderBy) {
        __typename
        id
        startDateTime
        endDateTime
        timezone
        isCanceled
        title
        description
        startDateTime
        coach {
          name
          id
        }
        coachFacility {
          name
          street
          city
          state
          zipCode
          name
          street
          city
          state
          zipCode
        }
        enrollments {
          id
          confirmationCode
          createdAt
          isCanceled
          student {
            id
            firstName
            lastName
            representatives {
              contact {
                externalId
                firstName
                lastName
                email
                phone
              }
            }
            contact {
              id
              firstName
              lastName
              email
              phone
              externalId
            }
          }
          transactions {
            amountInCents
            createdAt
            sourceType
            refund
            registrationPayment {
              id
              leftToRefundInCents
            }
          }
        }
        ... on Session {
          lessonType {
            id
            category
            priceInCents
            acceptsOnlinePayments
            durationInMinutes
          }
        }
        ... on GroupEvent {
          slug
          tags
          featuredImageUrl
          priceInCents
          totalPriceInCents
          acceptsOnlinePayments
          paymentRecurrenceSchedule
          numberOfPayments
          lessonCount
          registrationCloseDateTime
          externalRegistrationUrl
          registrationCount
          sessions {
            id
            startDateTime
            endDateTime
          }
        }
      }
    }
  }
`

export function useGetLeads() {
  return useQuery(GET_LEADS, { errorPolicy: 'all', fetchPolicy: 'no-cache' })
}

export function useGetLead(leadId) {
  return useQuery(GET_LEAD, {
    variables: { id: leadId },
  })
}

export function useGetUpcomingSessions(start, order) {
  return useQuery(UPCOMING_SESSIONS, {
    variables: {
      startDateTime: start,
      orderBy: [{ field: 'START_DATE_TIME', direction: order }],
    },
  })
}
