import React from 'react'
import { useAuth } from 'lib/auth'
import { flags, useFeatureFlags } from 'lib/feature-flags'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined'
import { MY_PGA_COM_HOST, STRIPE_CUSTOMER_PORTAL_URL } from 'env'
import pgaCoachPlusLogo from 'images/pga-coach-plus-logo.svg'
import { useCreateSubscriptionPaymentSession } from 'lib/graphql/mutations/create-subscription-payment-session'
import { useSnackbar, useSnackbarError } from 'lib/snackbar'

const SubscriptionDetails = () => {
  const { user } = useAuth()
  const hasPaidTierSubscription = user?.coach?.hasPaidTierSubscription
  const [isPaidTierActive] = useFeatureFlags([flags.FLAG_PAID_TIER_ACTIVE])
  const { enqueueSnackbar } = useSnackbar()
  const snackbarError = useSnackbarError(enqueueSnackbar)

  const successUrl = `${MY_PGA_COM_HOST}/pga-coach/checkout/success`
  const [
    callCreateSubscriptionPaymentSession,
    { loading },
  ] = useCreateSubscriptionPaymentSession()

  const handleUpgradeClick = async () => {
    try {
      const { data } = await callCreateSubscriptionPaymentSession({
        variables: { successUrl },
      })
      const {
        url,
        success,
        message,
      } = data?.createPgaCoachSubscriptionPaymentSession
      if (!success) {
        return snackbarError(message)
      }
      if (success && url) {
        window.open(url, '_blank').focus()
      }
    } catch (e) {
      snackbarError(e?.message)
    }
  }

  if (!isPaidTierActive) return null

  return (
    <Box my={6}>
      <Container maxWidth="md" component={Paper}>
        {loading && <LinearProgress />}
        {hasPaidTierSubscription ? (
          <Box
            display="flex"
            justifyContent="space-between"
            px={[1, 6]}
            py={[1, 4]}
          >
            <Box display="flex" flexDirection="column">
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginBottom: '8px', marginLeft: '2px' }}
              >
                Your Plan
              </Typography>
              <img src={pgaCoachPlusLogo} alt="PGA Coach logo" />
            </Box>
            <Button
              startIcon={<OpenInNewOutlinedIcon />}
              variant="outlined"
              color="primary"
              target="_blank"
              href={STRIPE_CUSTOMER_PORTAL_URL}
            >
              Manage Subscription
            </Button>
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="space-between"
            px={[1, 6]}
            py={[1, 4]}
          >
            <Box display="flex" flexDirection="column">
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginBottom: '8px' }}
              >
                Your Plan
              </Typography>
              <Typography variant="h6">PGA Coach</Typography>
            </Box>
            <Button
              startIcon={<OpenInNewOutlinedIcon />}
              variant="outlined"
              color="primary"
              onClick={handleUpgradeClick}
            >
              Upgrade Now
            </Button>
          </Box>
        )}
      </Container>
    </Box>
  )
}

export default SubscriptionDetails
