import { useState } from 'react'
import { Box, Button, Hidden, CircularProgress } from '@material-ui/core'
import { PageHeader } from 'components/page-header'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import MyFacilityReport from './my-facility-report'
import useStyles from './styles'
import MyBusinessInsights from './my-business-insights'
import Tab from '@mui/material/Tab'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useParams, useHistory } from 'react-router-dom'
import {
  GetBusinessInsightsByReportIdQuery,
  GetFacilityConstantsByReportIdQuery,
  GetReportsQuery,
} from '../reports/query'
import { useQuery } from '@apollo/client'
import { useMockQuery } from '../use-mock-query'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { generatePDF } from '../utils/pdf-generator'
import { useSnackbar } from 'notistack'
import { useAuth } from 'lib/auth'
import { saveAs } from 'file-saver'

const TAB_BUSINESS_INSIGHTS = 'TAB_BUSINESS_INSIGHTS'
const TAB_FACILITY_REPORT = 'TAB_FACILITY_REPORT'

const CoachBusinessReportsDashboard = () => {
  const classes = useStyles()
  const [tabValue, setTabValue] = useState(TAB_BUSINESS_INSIGHTS)
  const [isDownloading, setIsDownloading] = useState(false)
  const { reportId, sampleId } = useParams()
  const history = useHistory()
  const notistack = useSnackbar()
  const { user } = useAuth()

  const mockData = useMockQuery(sampleId)

  const { loading: queryLoading, data: queryData } = useQuery(
    GetFacilityConstantsByReportIdQuery,
    {
      variables: { reportId },
      skip: sampleId, // Skip if it's a sample report
    },
  )

  const { loading: insightsQueryLoading, data: insightsQueryData } = useQuery(
    GetBusinessInsightsByReportIdQuery,
    {
      variables: { reportId },
      skip: sampleId, // Skip if it's a sample report
    },
  )

  const facilityConstantsLoading = sampleId ? false : queryLoading
  const facilityConstantsData = sampleId
    ? { facilityValue: mockData.facility }
    : queryData

  const businessInsightsLoading = sampleId ? false : insightsQueryLoading
  const businessInsightsData = sampleId
    ? { businessInsights: mockData.data }
    : insightsQueryData

  const { data: reportsData } = useQuery(GetReportsQuery)

  const reportName =
    reportId && reportsData?.reports
      ? reportsData.reports.find(report => report.id === reportId)?.name ||
      'My Business Reporting'
      : 'My Business Reporting'

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const handleDownload = async () => {
    try {
      setIsDownloading(true)

      const activePanel = document.querySelector(
        `div[role="tabpanel"][id="tabpanel-${
          tabValue === TAB_BUSINESS_INSIGHTS
            ? 'business-insights'
            : 'facility-report'
        }"]`,
      )

      if (activePanel) {
        const coachName = user?.coach?.name || ''
        const blob = await generatePDF(activePanel, reportName, coachName)
        if (blob) {
          const reportType =
            tabValue === TAB_BUSINESS_INSIGHTS
              ? 'business-insights'
              : 'facility-report'
          const filename = `${reportName}-${reportType}-${coachName ||
            'coach'}.pdf`

          saveAs(blob, filename)
        }
      }
    } catch (error) {
      notistack.enqueueSnackbar('Failed to generate PDF', { variant: 'error' })
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <PageHeader
        title={sampleId ? 'Dr. Alison Curdt - Sample Report' : reportName}
        crumbs={[{ label: 'Reports', to: '/pga-coach/reports' }]}
        actions={
          <div style={{ marginRight: '0' }}>
            <Hidden smDown>
              <Button
                variant="outlined"
                color="primary"
                startIcon={
                  isDownloading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <FileDownloadOutlinedIcon />
                  )
                }
                onClick={handleDownload}
                disabled={
                  isDownloading ||
                  (tabValue === TAB_BUSINESS_INSIGHTS &&
                    !businessInsightsData?.businessInsights) ||
                  (tabValue === TAB_FACILITY_REPORT &&
                    !facilityConstantsData?.facilityValue)
                }
                className={classes.downloadButton}
              >
                {isDownloading ? 'Downloading...' : 'Download'}
              </Button>
              {!sampleId && ( // Only show edit button for real reports
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.editButton}
                  startIcon={<EditOutlinedIcon />}
                  onClick={() => {
                    history.push(`/pga-coach/reports/edit-report/${reportId}`)
                  }}
                >
                  Edit
                </Button>
              )}
            </Hidden>
            <Hidden mdUp>
              <div className={classes.mobileActions}>
                {!sampleId && (
                  <EditOutlinedIcon
                    style={{ color: '#0000008A', marginRight: '16px' }}
                    onClick={() => {
                      history.push(`/pga-coach/reports/edit-report/${reportId}`)
                    }}
                  />
                )}
                {isDownloading ? (
                  <CircularProgress size={20} />
                ) : (
                  <FileUploadOutlinedIcon
                    style={{ color: '#0000008A' }}
                    onClick={handleDownload}
                  />
                )}
              </div>
            </Hidden>
          </div>
        }
        className={classes.pageHeader}
      />
      <TabContext value={tabValue}>
        <Box className={classes.tabContainer}>
          <TabList onChange={handleTabChange} aria-label="business report tabs">
            <Tab
              label="My Coaching Performance"
              value={TAB_BUSINESS_INSIGHTS}
              id="tab-business-insights"
            />
            <Tab
              label="My Facility Report"
              value={TAB_FACILITY_REPORT}
              id="tab-facility-report"
            />
          </TabList>
        </Box>
        <TabPanel
          value={TAB_BUSINESS_INSIGHTS}
          id="tabpanel-business-insights"
          className={classes.tabPanel}
        >
          <Box className={classes.contentContainer}>
            <MyBusinessInsights
              businessInsightsLoading={businessInsightsLoading}
              businessInsights={businessInsightsData?.businessInsights}
              reportId={reportId}
            />
          </Box>
        </TabPanel>
        <TabPanel
          value={TAB_FACILITY_REPORT}
          id="tabpanel-facility-report"
          className={classes.tabPanel}
        >
          <Box className={classes.contentContainer}>
            <MyFacilityReport
              facilityConstantsLoading={facilityConstantsLoading}
              facilityConstants={facilityConstantsData?.facilityValue}
              reportId={reportId}
            />
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default CoachBusinessReportsDashboard
