import { Grid, useMediaQuery, useTheme } from '@material-ui/core'
import useStyles from './styles'
import CoachInsightsStatsCard from 'components/coach-insights-stats-card'
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { formatMoneyFromCents } from 'lib/utils/number'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined'
import CoachInsightsProfitChart from 'components/coach-insights-profit'
import { useState, useEffect, useCallback } from 'react'
import CoachInsightsFacilityChart from 'components/coach-insights-facility-chart'
import InfoTextContainer from 'components/info-text-container'
import { Skeleton } from '@material-ui/lab'
import EmptyPage from 'components/empty-page-state'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import { formatFromIntToCurrency, formatNumberWithCommas } from 'utils/number'
import CoachInsightsCTACard from 'components/coach-insights-cta-card'
import { useHistory } from 'react-router-dom'

const MyFacilityReport = ({
  facilityConstants,
  facilityConstantsLoading,
  reportId,
}) => {
  const classes = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  const history = useHistory()
  const [
    additionalRevenueToFacility,
    setAdditionalRevenueToFacility,
  ] = useState([])

  const getTotalRevenueToClub = useCallback(() => {
    const modelTotals =
      additionalRevenueToFacility?.reduce((acc, item) => {
        acc.pgaSuggested = (acc.pgaSuggested || 0) + item.pgaSuggestedModel
        acc.bvgi = (acc.bvgi || 0) + item.bvgiModel
        acc.agreed = (acc.agreed || 0) + item.agreedModel
        return acc
      }, {}) || {}

    return `$${formatFromIntToCurrency(
      Math.min(...Object.values(modelTotals)).toFixed(2),
    )} - $${formatFromIntToCurrency(
      Math.max(...Object.values(modelTotals)).toFixed(2),
    )}`
  }, [additionalRevenueToFacility])

  const renderSkeletonCard = () => (
    <div data-testid="skeleton-card" className={classes.skeletonCard}>
      <Skeleton variant="circle" width={40} height={40} />
      <Skeleton variant="text" width="60%" height={40} />
      <Skeleton variant="text" width="40%" />
    </div>
  )

  useEffect(() => {
    if (facilityConstants?.additionalRevenueToFacility) {
      setAdditionalRevenueToFacility(
        Object.values(facilityConstants.additionalRevenueToFacility).map(
          item => ({
            ...item,
            bvgiModel: item.bvgiModel / 100,
            agreedModel: item.agreedModel / 100,
            pgaSuggestedModel: item.pgaSuggestedModel / 100,
          }),
        ),
      )
    } else {
      setAdditionalRevenueToFacility([])
    }
  }, [facilityConstants])

  return (
    <div>
      <div className={classes.gridContainer}>
        {// TODO: Add link to learn more about models
        isMobile && (
          <InfoTextContainer>
            <span>
              This is the information about what this specific model means and
              how it was calculated.{' '}
              <u>
                <b>Learn about models</b>
              </u>
            </span>
          </InfoTextContainer>
        )}
        {facilityConstantsLoading && (
          <Grid container spacing={isMobile ? 2 : 3}>
            {[...Array(6)].map((_, index) => (
              <Grid item xs={6} sm={4} key={index}>
                {renderSkeletonCard()}
              </Grid>
            ))}
            <Grid item sm={12} xs={12}>
              <Skeleton variant="rect" height={300} />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Skeleton variant="rect" height={300} />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Skeleton variant="rect" height={300} />
            </Grid>
          </Grid>
        )}
        {!facilityConstantsLoading && facilityConstants ? (
          <Grid container spacing={isMobile ? 2 : 3}>
            <Grid item xs={6} sm={3}>
              <CoachInsightsStatsCard
                icon={MonetizationOnOutlinedIcon}
                value={`$${formatFromIntToCurrency(
                  (
                    facilityConstants?.directPaymentToFacilityInCents?.reduce(
                      (total, payment) => total + payment.value,
                      0,
                    ) / 100
                  ).toFixed(2),
                )}`}
                description="Payments to Club"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <CoachInsightsStatsCard
                icon={AccessTimeOutlinedIcon}
                value={formatNumberWithCommas(
                  facilityConstants?.hoursWithProfessional,
                )}
                description="Hours with Professional"
                valueTestId="hours-professional-value"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <CoachInsightsStatsCard
                icon={GroupOutlinedIcon}
                value={formatNumberWithCommas(facilityConstants?.totalStudents)}
                description="Total Students"
                valueTestId="total-students-value"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <CoachInsightsStatsCard
                icon={GroupAddOutlinedIcon}
                value={`${facilityConstants?.participantsTurnedMembers}`}
                description="Students Converted to Members"
                valueTestId="converted-members-value"
              />
            </Grid>
            <Grid item xs={12}>
              <InfoTextContainer>
                <span>
                  <b className={classes.infoTextTitle}>
                    Additional Estimated Revenue to Facility
                  </b>
                  <br />
                  The following models represent different revenue-sharing
                  scenarios between professionals and facilities. The PGA
                  Suggested Model follows industry standards, the BVGI Model is
                  our recommended approach, and the BVGE Model reflects
                  currently agreed terms. These estimates help facilities
                  understand potential revenue opportunities from professional
                  services.
                </span>
              </InfoTextContainer>
            </Grid>
            <Grid item xs={12} sm={4}>
              <CoachInsightsStatsCard
                icon={AttachMoneyIcon}
                value={`$${formatFromIntToCurrency(
                  additionalRevenueToFacility
                    ?.reduce(
                      (total, payment) => total + payment.pgaSuggestedModel,
                      0,
                    )
                    .toFixed(2),
                )}`}
                description="PGA Suggested Model"
                avatarBackground="#043362"
                iconColor="#FFF"
                valueTestId="pga-suggested-model-value"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CoachInsightsStatsCard
                icon={AttachMoneyIcon}
                value={`$${formatFromIntToCurrency(
                  additionalRevenueToFacility
                    ?.reduce((total, payment) => total + payment.bvgiModel, 0)
                    .toFixed(2),
                )}`}
                description="BVGI Model"
                avatarBackground="#3268A7"
                iconColor="#FFF"
                valueTestId="bvgi-model-value"
              />
            </Grid>
            {additionalRevenueToFacility?.reduce(
              (total, payment) => total + payment.agreedModel,
              0,
            ) > 0 ? (
              <Grid item xs={12} sm={4}>
                <CoachInsightsStatsCard
                  icon={AttachMoneyIcon}
                  value={`$${formatFromIntToCurrency(
                    additionalRevenueToFacility
                      ?.reduce(
                        (total, payment) => total + payment.agreedModel,
                        0,
                      )
                      .toFixed(2),
                  )}`}
                  description="BVGE Model"
                  avatarBackground="#9E9E9E"
                  iconColor="#FFF"
                  valueTestId="bvge-model-value"
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={4}>
                <CoachInsightsCTACard
                  icon={AttachMoneyIcon}
                  description="We need your Facility Metrics in order to calculate the BVGE Model."
                  buttonText="EDIT REPORT"
                  buttonOnClick={() =>
                    history.push(`/pga-coach/reports/edit-report/${reportId}`)
                  }
                />
              </Grid>
            )}
            {!isMobile && (
              <Grid item xs={12}>
                <CoachInsightsProfitChart
                  lineChartData={additionalRevenueToFacility}
                  title={'Total Revenue to Club'}
                  value={getTotalRevenueToClub()}
                  // TODO: Add link to learn more about models
                  infoText={
                    <span>
                      This is the information about what this specific model
                      means and how it was calculated.{' '}
                      <u>
                        <b>Learn about models</b>
                      </u>
                    </span>
                  }
                />
              </Grid>
            )}
            {isMobile && (
              <Grid item xs={12}>
                <CoachInsightsFacilityChart
                  facilityData={facilityConstants}
                  selectedOption={{
                    label: 'PGA Suggested Model',
                    value: 'pgaSuggestedModel',
                  }}
                  title={'Total Revenue to Club'}
                  value={`${formatMoneyFromCents(
                    facilityConstants?.directPaymentToFacilityInCents,
                  )}`}
                />
              </Grid>
            )}
            {isMobile && (
              <Grid item xs={12}>
                <CoachInsightsFacilityChart
                  facilityData={facilityConstants}
                  selectedOption={{ label: 'BVGE Model', value: 'agreedModel' }}
                  title={'Total Revenue to Club'}
                  value={`${formatMoneyFromCents(
                    facilityConstants?.directPaymentToFacilityInCents,
                  )}`}
                />
              </Grid>
            )}
            {isMobile && (
              <Grid item xs={12}>
                <CoachInsightsFacilityChart
                  facilityData={facilityConstants}
                  selectedOption={{ label: 'BVGI Model', value: 'bvgiModel' }}
                  title={'Total Revenue to Club'}
                  value={`${formatMoneyFromCents(
                    facilityConstants?.directPaymentToFacilityInCents,
                  )}`}
                />
              </Grid>
            )}
          </Grid>
        ) : (
          <div
            data-testid="facility-report-empty-state"
            className={classes.gridContainer}
          >
            {!facilityConstantsLoading && (
              <EmptyPage
                actionFn={() => {}}
                IconComp={ShowChartIcon}
                title="My Facility Report"
                caption="There is currently no facility report data available for this time period."
                buttonText="Create Report"
                actionFnSecondary={() => {}}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default MyFacilityReport
