import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import MUIRichTextEditor from 'mui-rte'
import { convertToRaw } from 'draft-js'
import { draftToMarkdown } from 'markdown-draft-js'
import { useFormContext } from 'react-hook-form'

const useStyles = makeStyles(theme => ({
  counterText: {
    color: props =>
      props.isError ? theme.palette.error.main : theme.palette.text.secondary,
    position: 'absolute',
    bottom: theme.spacing(0.5),
    right: theme.spacing(1),
    fontSize: '0.75rem',
    marginTop: theme.spacing(0.5),
    textAlign: 'right',
  },
}))

const RichTextCounter = ({
  characterLimit,
  onChange,
  defaultValue,
  helperText,
  fieldName,
  ...props
}) => {
  const [charCount, setCharCount] = useState(0)
  const [isError, setIsError] = useState(false)
  const styles = useStyles({ isError })
  const { setValue, errors } = useFormContext()
  const error = errors[fieldName]

  const handleChange = editorState => {
    const content = convertToRaw(editorState.getCurrentContent())
    const markdown = draftToMarkdown(content, {})
    const count = markdown.length

    setCharCount(count)
    setIsError(count > characterLimit)

    if (onChange) {
      onChange(editorState)
      setValue(fieldName, markdown)
    }
  }

  return (
    <>
      <MUIRichTextEditor
        {...props}
        defaultValue={defaultValue}
        onChange={handleChange}
        error={error || isError}
      />
      <Typography className={styles.counterText}>
        {`${charCount}/${characterLimit}`}
      </Typography>
      {(error || isError) && helperText && (
        <Typography color="error" variant="caption">
          {helperText}
        </Typography>
      )}
    </>
  )
}

export default RichTextCounter
