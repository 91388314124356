import React from 'react'
import { DateTime } from 'luxon'
import Container from '@material-ui/core/Container'
import BookingsList from './bookings-list'
import Disclaimer from 'components/disclaimer'
import PGAIcon from 'images/pga-seal-icon.svg'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  tabWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      padding: 0,
    },
  },
  showCancellations: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '20px',
    },
  },
  containerStyles: {
    [theme.breakpoints.down('xs')]: {
      padding: '0',
      marginBottom: '60px',
    },
  },
}))

const BookingsTabs = ({ selectedTab = 0, onboardingSchedule }) => {
  const classes = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))

  const isOnboardingComplete =
    onboardingSchedule.isGroupEventCreated ||
    onboardingSchedule.isBookingTypeCreated

  const upcomingBookingsDateRange = {
    from: DateTime.now().toISO(),
    to: null,
  }

  const pastBookingsDateRange = {
    from: DateTime.now()
      .minus({ days: 30 })
      .startOf('day')
      .toISO(),
    to: DateTime.now().toISO(),
  }

  return (
    <Container className={classes.containerStyles}>
      {!isOnboardingComplete && (
        <Box mt={3}>
          <Disclaimer
            logo={PGAIcon}
            description="For further assistance with your PGA Schedule setup, revisit the PGA schedule page to track your progress and access additional information."
            action={
              <Button
                variant={isMobile ? 'text' : 'outlined'}
                href={'/pga-coach/set-up-schedule/'}
              >
                Schedule set up guide
              </Button>
            }
          />
        </Box>
      )}
      {selectedTab === 0 && (
        <BookingsList
          timeframe="upcoming"
          startDateTime={upcomingBookingsDateRange}
          emptyStateTitle="No Scheduled Sessions"
          emptyStateSubtitle="Customers that schedule a session with you will appear here. First you’ll need to set your availability which can be found in Settings."
        />
      )}
      {selectedTab === 1 && (
        <BookingsList
          reverse
          timeframe="past"
          startDateTime={pastBookingsDateRange}
          emptyStateTitle="No past sessions"
          emptyStateSubtitle="Previous sessions will appear here after they occur. First you'll need to set your availability which can be found in Settings  -->"
          selectedTab={selectedTab}
        />
      )}
    </Container>
  )
}

export default BookingsTabs
