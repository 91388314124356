import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import Toolbar from '@material-ui/core/Toolbar'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Hidden from '@material-ui/core/Hidden'
import Badge from '@material-ui/core/Badge'
import MenuIcon from '@material-ui/icons/Menu'
import { IconMenuOpen, IconMenuClose } from 'components/atoms/icons'
import { useTheme } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'
import AccountDropdown from 'components/account-dropdown'
import BrandSwitcher from './brand-switcher'
import SidebarMenuItem from './sidebar-menu-item'
import ContactButton from 'components/contact-button'
import useStyles from './styles'
import { useIsMobile, useIsTablet } from 'lib/hooks'
import { getAssumedUser } from 'lib/auth/impersonation'
import { useHideMobileIntercom, useRemoveIntercom } from 'lib/support'
import clsx from 'clsx'

export default function Shell({
  logo = { src: '', alt: '' },
  menuItems = [],
  showBrandSwitcher = true,
  showAccountDropdown = true,
  toolbarContent = null,
  unviewedLeadCount = null,
  children,
}) {
  const assumedUser = getAssumedUser()
  const location = useLocation()
  const classes = useStyles({ assumedUser })
  const theme = useTheme()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  const [isTabletNavOpen, setIsTabletNavOpen] = useState(true)

  const handleDrawerToggle = () => {
    if (!isTablet) return
    if (isTablet && !isMobile) setIsTabletNavOpen(!isTabletNavOpen)
    if (isMobile) setIsMobileNavOpen(!isMobileNavOpen)
  }

  useEffect(() => {
    if (isTablet) {
      setIsTabletNavOpen(false)
    } else {
      setIsTabletNavOpen(true)
    }
    if (isMobile) {
      setIsTabletNavOpen(true)
    }
  }, [isTablet, isMobile])

  useHideMobileIntercom()
  const removeIntercomWhileAssumingUser = assumedUser
    ? useRemoveIntercom
    : () => {}
  removeIntercomWhileAssumingUser()

  const menu = (
    <List className={classes.menu}>
      {menuItems.map(item => {
        const MenuItemComponent = item?.component
          ? item.component
          : SidebarMenuItem
        return (
          <MenuItemComponent
            {...{
              onClick: isMobile ? handleDrawerToggle : undefined,
              key: item.path || item.label,
              ...item,
              location,
              isTabletNavOpen,
              setIsTabletNavOpen,
            }}
          />
        )
      })}
    </List>
  )

  const drawerContainer =
    window !== undefined ? () => window.document.body : undefined

  return (
    <>
      {theme.palette.body?.background && (
        <Helmet>
          <style>{`body { background-color: ${theme.palette.body.background}; }`}</style>
        </Helmet>
      )}
      <Box display="flex">
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            {menuItems.length > 0 && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={`${
                  isMobile ? classes.mobileMenuButton : classes.tabletMenuButton
                }`}
              >
                <Badge
                  invisible={!location.pathname.startsWith('/pga-coach')}
                  classes={{ badge: classes.mobileMenuButtonBadge }}
                  color={unviewedLeadCount > 0 ? 'error' : 'primary'}
                  variant="dot"
                  overlap="rectangular"
                >
                  {isMobile ? (
                    <MenuIcon />
                  ) : (
                    <>
                      {!isTabletNavOpen ? <IconMenuOpen /> : <IconMenuClose />}
                    </>
                  )}
                </Badge>
              </IconButton>
            )}
            {toolbarContent || (
              <>
                {showBrandSwitcher ? (
                  <BrandSwitcher logo={logo} />
                ) : (
                  <img
                    src={logo.src}
                    alt={logo.alt}
                    style={{
                      display: 'block',
                      width:
                        (isMobile
                          ? logo.mobileWidth ?? logo.width
                          : logo.width) ?? '110px',
                      height:
                        (isMobile
                          ? logo.mobileHeight ?? logo.height
                          : logo.height) ?? 'auto',
                    }}
                  />
                )}
                {showAccountDropdown && !isMobile && <Box flexGrow={1} />}
                {location.pathname.includes('messages') && <ContactButton />}
                {showAccountDropdown && <AccountDropdown />}
              </>
            )}
          </Toolbar>
        </AppBar>
        {menuItems.length > 0 && ( // only show drawer if there are menu items to populate it
          <nav
            className={`${classes.drawer} ${
              !isTabletNavOpen && isTablet ? classes.drawerContainerClosed : ''
            }`}
          >
            <Hidden smDown implementation="css">
              <Drawer
                color="primary"
                className={classes.drawer}
                variant="persistent"
                open
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                <div className={classes.toolbarSpacing} />
                {menu}
              </Drawer>
            </Hidden>
            <Hidden xsUp implementation="css">
              <Drawer
                container={drawerContainer}
                variant="temporary"
                anchor="left"
                open={isMobileNavOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {menu}
              </Drawer>
            </Hidden>
            <Hidden mdUp xsDown implementation="css">
              <Drawer
                variant="permanent"
                open={isTabletNavOpen}
                className={clsx(classes.drawer, {
                  [classes.drawerOpen]: isTabletNavOpen,
                  [classes.drawerClose]: !isTabletNavOpen,
                })}
                classes={{
                  paper: clsx({
                    [classes.drawerOpen]: isTabletNavOpen,
                    [classes.drawerClose]: !isTabletNavOpen,
                  }),
                }}
              >
                <div className={classes.toolbarSpacing} />
                {menu}
              </Drawer>
            </Hidden>
          </nav>
        )}
        <main className={classes.mainContent}>
          <div className={classes.toolbarSpacing} />
          {children}
        </main>
      </Box>
    </>
  )
}
