import React from 'react'
import { get } from 'lodash'
import LinearProgress from '@material-ui/core/LinearProgress'
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined'
import Error from 'components/error'
import LeadsView from './leads-view'
import EmptyPageState from 'components/empty-page-state'

export default React.memo(
  ({
    loading,
    error,
    data,
    setUnviewedLeadCount,
    refetch,
    studentRefetch,
    freeTierLimitsReached,
  }) => {
    if (loading) return <LinearProgress color="primary" />
    if (error) return <Error error={error} />
    if (data != null && data.length === 0) {
      return (
        <EmptyPageState
          IconComp={() => <PermContactCalendarOutlinedIcon fontSize="large" />}
          title="Leads"
          caption="Your new leads will appear here."
          removeButton
        />
      )
    }
    if (data) {
      setUnviewedLeadCount(data.filter(lead => lead.viewedAt === null).length)
      const searchableItems = data.map(item => ({
        ...item,
        firstName: get(item, ['contact', 'firstName']),
        lastName: get(item, ['contact', 'lastName']),
      }))
      return (
        <LeadsView
          searchableItems={searchableItems}
          setUnviewedLeadCount={setUnviewedLeadCount}
          refetch={refetch}
          studentRefetch={studentRefetch}
          freeTierLimitsReached={freeTierLimitsReached}
        />
      )
    }
  },
)
