import { gql, useMutation } from '@apollo/client'

const CREATE_SUBSCRIPTION_PAYMENT_SESSION = gql`
  mutation CreateSubscriptionPaymentSession($successUrl: String!) {
    createPgaCoachSubscriptionPaymentSession(successUrl: $successUrl) {
      message
      success
      url
    }
  }
`

export function useCreateSubscriptionPaymentSession() {
  const [createSubscriptionPaymentSession, result] = useMutation(
    CREATE_SUBSCRIPTION_PAYMENT_SESSION,
  )
  return [createSubscriptionPaymentSession, result]
}
