import React from 'react'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  base: {
    background: '#faf5ef',
    borderRadius: '10px',
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginRight: theme.spacing(2),
  },
  logoWrapper: {
    minWidth: 'auto',
    width: 'auto',
    padding: '0 16px',
    textAlign: 'center',
  },
  logo: {
    width: '50px',
    minWidth: '50px',
  },
  body: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0),
    },
  },
}))

const DisclaimerWithTwoCta = ({
  logo,
  alt = 'Logo',
  description,
  icon,
  leftCta,
  rightCta,
  show,
}) => {
  const classes = useStyles()

  if (!show) return null
  return (
    <Box display="flex" alignItems="center" className={classes.base}>
      <Box className={classes.logoWrapper}>
        {logo && <img src={logo} alt={alt} className={classes.logo} />}
        {icon && icon}
      </Box>
      <Divider orientation="vertical" flexItem className={classes.divider} />
      <Box display="flex" flexDirection="column" width="100%">
        <Typography className={classes.body}>{description}</Typography>
        <Box mt={1} display="flex" justifyContent="space-between">
          {leftCta}
          {rightCta}
        </Box>
      </Box>
    </Box>
  )
}

export default DisclaimerWithTwoCta
