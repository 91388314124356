import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  chip: {
    flex: '0 1 45%',
    cursor: 'pointer',
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.secondary.contrastText,
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: '4px',
    fontFamily: 'Roboto Condensed',
    fontWeight: '700',
    letterSpacing: 1,
    height: '22px',
    fontSize: '12px',
    marginBottom: '.25rem',
    position: 'relative',
    textTransform: 'uppercase',
    '& span': {
      paddingLeft: '.25rem',
      paddingRight: '.25rem',
    },
  },
  filledChip: {
    flex: '0 1 45%',
    cursor: 'pointer',
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.dark,
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: '4px',
    fontFamily: 'Roboto Condensed',
    fontWeight: '700',
    letterSpacing: 1,
    height: '22px',
    fontSize: '12px',
    marginBottom: '.25rem',
    marginRight: '.25rem',
    position: 'relative',
    textTransform: 'uppercase',
    '& span': {
      paddingLeft: '.25rem',
      paddingRight: '.25rem',
    },
    '& svg': {
      fill: theme.palette.secondary.contrastText,
      width: 14,
      height: 14,
      marginRight: '1px',
      marginBottom: '2px',
    },
  },
  chipsSection: {
    position: 'absolute',
    minHeight: '3rem',
    top: '60px',
    left: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '10px',
    '& .chip:nth-child(odd)': {
      marginRight: '.25rem',
    },
    alignItems: 'flex-end',
  },
  lessonPackDetails: {
    fontWeight: '600',
  },
  contentRootV2: {
    padding: '8px 24px 0 24px',
  },
  detailsRow: {
    margin: '20px 0',
    flexDirection: 'column',
  },
  actionButtonContainer: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    display: 'flex',
  },
  description: {
    marginTop: '2rem',
    '& p': {
      margin: '0',
    },
  },
  title: {
    color: theme.palette.primary.main,
  },
  textButton: {
    paddingLeft: '0',
    textDecoration: 'none',
    '& span': {
      display: 'flex',
      alignItems: 'center',
    },
    '& svg': {
      transition: '0.1s ease-in-out',
    },
    '&:hover': {
      color: theme.palette.primary.light,
      textDecoration: 'none',
      '& svg': {
        transform: 'translateX(4px)',
      },
    },
  },
}))
