import React, { lazy, Suspense } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withProtectedRoute, roles } from 'lib/auth'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import Shell from 'components/shell/shell'
import themes, { extendTheme } from 'themes'
import { ReactComponent as HomeIcon } from 'images/icons/home.svg'
import { ReactComponent as GolfIcon } from 'images/icons/sports-golf.svg'
import PGAIcon from 'images/pga-logo-navy.png'

const ProgramAdmin = lazy(() => import('pages/admin'))
const ProgramAdminCoaches = lazy(() => import('pages/admin/coaches/coaches'))
const ProgramAdminPlayers = lazy(() => import('pages/admin/players/players'))
const ProgramAdminFacilities = lazy(() =>
  import('pages/admin/facilities/facilities'),
)
const ProgramAdminEditFacility = lazy(() =>
  import('pages/admin/facilities/edit'),
)
const ProgramAdminPrograms = lazy(() => import('pages/admin/programs'))
const ProgramAdminPlayerTransactions = lazy(() =>
  import('pages/admin/players/transactions'),
)
const ProgramAdminEditPlayer = lazy(() => import('pages/admin/players/edit'))
const ProgramAdminCoachTransactions = lazy(() =>
  import('pages/admin/coaches/transactions'),
)
const ProgramAdminTransaction = lazy(() => import('pages/admin/transaction'))
const ProgramAdminChampionshipSeasonRegions = lazy(() =>
  import('pages/admin/championship-season-regions'),
)
const ProgramAdminChampionshipSeasonEdit = lazy(() =>
  import('pages/admin/championship-season-regions/edit-championship-season'),
)
const Leagues = lazy(() => import('pages/admin/leagues'))
const EditLeagues = lazy(() => import('pages/admin/leagues/edit-league'))
const ChampionshipSeason = lazy(() => import('pages/admin/championship-season'))
const ChampionshipSeasonEdit = lazy(() =>
  import('pages/admin/championship-season/edit'),
)
const ProgramAdminDiscountCodes = lazy(() =>
  import('pages/admin/discount-codes'),
)
const ProgramAdminAddDiscountCode = lazy(() =>
  import('pages/admin/discount-codes/discount-code-form'),
)

const JerseyStock = lazy(() => import('pages/admin/jersey-stock'))

const DevTools = lazy(() => import('pages/admin/dev-tools'))

const RequestApproval = lazy(() => import('pages/admin/request-approval'))

const ProgramAdminRegionals = lazy(() =>
  import('pages/admin/regionals/regionals'),
)

const ProgramAdminNationals = lazy(() =>
  import('pages/admin/nationals/nationals'),
)

const ManualCoachRequirements = lazy(() =>
  import('pages/admin/manual-coach-requirements'),
)

const CoachesWithJRLUsers = lazy(() =>
  import('pages/admin/coaches-with-jrl-users'),
)

const FulfillmentOrders = lazy(() => import('pages/admin/fulfillment-orders'))

const FulfillmentOrderEdit = lazy(() =>
  import('pages/admin/fulfillment-orders/edit'),
)

function AdminRoutes() {
  const { path } = useRouteMatch()

  const menuItems = [
    {
      icon: <HomeIcon />,
      label: 'Admin',
      path: `${path}`,
      exact: true,
    },
    {
      icon: <GolfIcon />,
      label: 'Regional Championships',
      path: `${path}/regionals`,
      exact: true,
    },
    {
      icon: <GolfIcon />,
      label: 'National Championships',
      path: `${path}/nationals`,
      exact: true,
    },
  ]

  return (
    <ThemeProvider theme={extendTheme(themes.juniorLeague)}>
      <Helmet>
        <title>Admin</title>
        <meta name="description" content="Admin" />
        <link rel="icon" href="/jrl-favicon.ico" />
      </Helmet>
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Shell
          logo={{
            src: PGAIcon,
            alt: 'PGA Logo',
            width: '48px',
            mobileWidth: '48px',
          }}
          menuItems={menuItems}
        >
          <Suspense fallback={<LinearProgress color="secondary" />}>
            <Switch>
              <Route
                exact
                path={`${path}/program-requests/:programRequestId`}
                component={RequestApproval}
              />
              <Route exact path={path} component={ProgramAdmin} />
              <Route
                exact
                path={`${path}/coaches`}
                component={ProgramAdminCoaches}
              />
              <Route
                exact
                path={`${path}/players`}
                component={ProgramAdminPlayers}
              />
              <Route
                exact
                path={`${path}/facilities`}
                component={ProgramAdminFacilities}
              />
              <Route
                exact
                path={`${path}/facilities/:facilityId/edit`}
                component={ProgramAdminEditFacility}
              />
              <Route
                exact
                path={`${path}/programs`}
                component={ProgramAdminPrograms}
              />
              <Route
                exact
                path={`${path}/players/:playerId/transactions`}
                component={ProgramAdminPlayerTransactions}
              />
              <Route
                exact
                path={`${path}/players/:playerId/edit`}
                component={ProgramAdminEditPlayer}
              />
              <Route
                exact
                path={`${path}/coaches/:coachId/transactions`}
                component={ProgramAdminCoachTransactions}
              />
              <Redirect
                exact
                from={`${path}/players/:playerId/transactions/:transactionId`}
                to={`${path}/transactions/:transactionId`}
              />
              <Route
                exact
                path={`${path}/transactions/:transactionId`}
                component={ProgramAdminTransaction}
              />
              <Route
                exact
                path={`${path}/discount-codes`}
                component={ProgramAdminDiscountCodes}
              />
              <Route
                exact
                path={`${path}/discount-codes/new`}
                component={ProgramAdminAddDiscountCode}
              />
              <Route
                exact
                path={`${path}/discount-codes/:discountId`}
                component={ProgramAdminAddDiscountCode}
              />
              <Route
                exact
                path={`${path}/championship-season-regions`}
                component={ProgramAdminChampionshipSeasonRegions}
              />
              <Route
                exact
                path={`${path}/championship-season-regions/:seasonId`}
                component={ProgramAdminChampionshipSeasonEdit}
              />
              <Route exact path={`${path}/leagues`} component={Leagues} />
              <Route exact path={`${path}/leagues/:leagueId`}>
                <EditLeagues />
              </Route>
              <Route
                exact
                path={`${path}/championship-season`}
                component={ChampionshipSeason}
              />
              <Route
                exact
                path={`${path}/championship-season/:programId`}
                component={ChampionshipSeasonEdit}
              />
              <Route
                exact
                path={`${path}/jersey-stock`}
                component={JerseyStock}
              />
              <Route
                exact
                path={`${path}/regionals`}
                component={ProgramAdminRegionals}
              />
              <Route
                exact
                path={`${path}/nationals`}
                component={ProgramAdminNationals}
              />
              <Route exact path={`${path}/dev-tools`} component={DevTools} />
              <Route
                exact
                path={`${path}/coach-requirements`}
                component={ManualCoachRequirements}
              />
              <Route
                exact
                path={`${path}/coaches-with-jrl-users`}
                component={CoachesWithJRLUsers}
              />
              <Route
                exact
                path={`${path}/fulfillment-orders`}
                component={FulfillmentOrders}
              />
              <Route
                exact
                path={`${path}/fulfillment-orders/:fulfillmentOrderId`}
                component={FulfillmentOrderEdit}
              />
              <Route
                path={`${path}/*`}
                render={() => <div>Page Not Found!</div>}
              />
            </Switch>
          </Suspense>
        </Shell>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default withProtectedRoute(AdminRoutes, [
  roles.SUPER_USER,
  roles.ADMIN,
  roles.PEC,
  roles.SECTION_STAFF,
])
