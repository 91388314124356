import React, { useState } from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import CoachOfferingCard from 'components/coach-offering-card'
import StepDescriptionOfferings from '../step-description-offerings'
import { useStyles } from './styles'
import LocationDropdown from './location-dropdown'
import UngroupedLessonTypes from './ungrouped-lesson-types'
import CoachMobileContactBanner from 'components/coach-contact-banners/coach-mobile-contact-banner'
import CoachLargeContactBanner from 'components/coach-contact-banners/coach-large-contact-banner'
import { useIsTablet } from 'lib/hooks'
import CoachGroupEvents from './coach-group-events'
import PropTypes from 'prop-types'
import Programs from './programs'
import Dialog from 'components/dialog'
import { ThemeProvider } from '@material-ui/core'
import themes, { extendTheme } from 'themes'
import LeadForm from 'pages/coach-leads/lead-form'
import { useImpressionEvent } from 'lib/gtm'

const PickLessonTypeLayoutWithLeadForm = ({
  data,
  coach,
  coachIsViewingOwnSchedule,
  lessonCreditBalances,
  returnUrl,
}) => {
  const { coachName, coachLocations, lessonTypes } = data
  const [selectedFacility, setSelectedFacility] = useState(null)
  const [groupEventsLocations, setGroupEventsLocations] = useState([])
  const [programsLocations, setProgramsLocations] = useState([])
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const classes = useStyles()
  const isTablet = useIsTablet()
  const [hasOneLocaton, setHasOneLocation] = useState(false)

  let numberOfLessons = 0
  const lessonTypesSingleLesson = {}
  const lessonTypesWithPacks = {}

  const selectOfferingsText = 'Select from this list of coach offerings below.'
  const description = hasOneLocaton
    ? selectOfferingsText
    : `${selectOfferingsText} You can filter by the coach's available locations as well.`
  const { preferences, freeTierLimitsReached } = coach
  const isLeadsLimitReached = freeTierLimitsReached.includes('LEADS')
  const showContactBanner =
    !coachIsViewingOwnSchedule &&
    !isLeadsLimitReached &&
    preferences.acceptsLeads

  let hasLessonPacks = false
  const addSession = (recipient, lt, session) => {
    if (!Array.isArray(recipient[lt])) {
      recipient[lt] = []
    }
    recipient[lt].push(session)
  }

  Object.entries(lessonTypes).forEach(([lessonType, sessions]) => {
    sessions.forEach(session => {
      numberOfLessons++
      if (session.lessonPacks.length > 0) {
        hasLessonPacks = true
        const isPurchased = lessonCreditBalances?.some(
          credit => credit.lessonType.id === session.id && credit.quantity > 0,
        )
        if (isPurchased) {
          addSession(lessonTypesSingleLesson, lessonType, session)
          addSession(lessonTypesWithPacks, lessonType, session)
        } else {
          addSession(lessonTypesWithPacks, lessonType, session)
        }
      } else {
        addSession(lessonTypesSingleLesson, lessonType, session)
      }
    })
  })

  useImpressionEvent({
    event: 'view-coach-offerings',
    coach_name: coachName,
    coach_id: coach?.id,
    available_booking_count: numberOfLessons,
    locations: coachLocations.map(facility => facility.name),
  })

  // @TODO: Remove reference to this and Dialog once consolidate-lead-forms flag is permanently enabled since we will just be redirecting to new form.
  const handleGetInTouchClick = () => {
    setIsDialogOpen(true)
  }

  return (
    <div className={classes.root}>
      <Container className={classes.outerContainer} pb={10}>
        <Grid>
          {/* coach information section */}
          <Grid item xs={12}>
            <CoachOfferingCard
              coach={{
                name: coachName,
                coachProfile: coach.coachProfile,
                returnUrl: returnUrl,
              }}
            />
          </Grid>
          {/* Coach Offerings section */}
          <Grid item xs={12}>
            <StepDescriptionOfferings
              title="Coach Offerings"
              description={description}
            />
            {!hasOneLocaton && (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className={classes.locationDropdown}
              >
                <LocationDropdown
                  selectedFacility={selectedFacility}
                  setSelectedFacility={setSelectedFacility}
                  locations={coachLocations}
                  groupEventsLocations={groupEventsLocations}
                  setHasOneLocation={setHasOneLocation}
                  programsLocations={programsLocations}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Programs
                coach={coach}
                classes={classes}
                setProgramsLocations={setProgramsLocations}
                selectedFacility={selectedFacility}
              />
            </Grid>
            <Grid item xs={12}>
              {hasLessonPacks && !coachIsViewingOwnSchedule && (
                <UngroupedLessonTypes
                  title="Lesson Packs"
                  selectedFacility={selectedFacility}
                  lessonTypes={lessonTypesWithPacks}
                  coachName={coachName}
                  coachExternalId={coach?.externalId}
                  classes={classes}
                  isSingleSession={false}
                />
              )}
              <UngroupedLessonTypes
                title="Private Instruction"
                selectedFacility={selectedFacility}
                lessonTypes={lessonTypesSingleLesson}
                classes={classes}
                lessonCreditBalances={lessonCreditBalances}
                isSingleSession={true}
                coachExternalId={coach?.externalId}
                coachName={coachName}
                coachIsViewingOwnSchedule={coachIsViewingOwnSchedule}
              />
              <CoachGroupEvents
                coach={coach}
                classes={classes}
                selectedFacility={selectedFacility}
                setGroupEventsLocations={setGroupEventsLocations}
              />
              {showContactBanner && !isTablet ? (
                <CoachLargeContactBanner
                  coach={coach}
                  handleGetInTouchClick={handleGetInTouchClick}
                />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {showContactBanner && isTablet ? (
        <CoachMobileContactBanner
          coach={coach}
          handleGetInTouchClick={handleGetInTouchClick}
        />
      ) : null}
      <Dialog
        isOpen={isDialogOpen}
        showCloseButton
        fullScreen={isTablet}
        maxWidth={!isTablet ? '80%' : null}
        onClose={() => setIsDialogOpen(false)}
      >
        {() => {
          return (
            <ThemeProvider theme={extendTheme(themes.offeringsRefresh)}>
              <LeadForm coach={coach} source="MYPGA_SCHEDULE_CONTACT_BUTTON" />
            </ThemeProvider>
          )
        }}
      </Dialog>
    </div>
  )
}
PickLessonTypeLayoutWithLeadForm.propTypes = {
  data: PropTypes.object.isRequired,
  coach: PropTypes.shape({
    coachProfile: PropTypes.shape({
      profileUrl: PropTypes.string,
    }),
    externalId: PropTypes.string,
  }),
  lessonCreditBalances: PropTypes.array,
  coachIsViewingOwnSchedule: PropTypes.bool,
  returnUrl: PropTypes.string,
}

export default PickLessonTypeLayoutWithLeadForm
