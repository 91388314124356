import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { useSnackbar, useSnackbarError } from 'lib/snackbar'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import LocationDropdown from '../../../schedule/SelectLessonType/location-dropdown'
import { get } from 'lodash'
import BookingTypesList from './booking-types-list'
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined'
import EmptyBookingTypes from 'components/empty-page-state'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import useStyles from './styles'
import DisclaimerWithTwoCta from 'components/disclaimer-with-two-cta'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Button from '@material-ui/core/Button'
import { GET_LESSON_TYPES_AND_COACH_FACILITIES } from './queries'
import { MY_PGA_COM_HOST } from 'env'
import { flags, useFeatureFlags } from 'lib/feature-flags'
import { useCreateSubscriptionPaymentSession } from 'lib/graphql/mutations/create-subscription-payment-session'

const BookingTypesView = ({ setShouldShowDisclaimer }) => {
  const classes = useStyles()
  const history = useHistory()
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'))
  const [selectedFacility, setSelectedFacility] = useState({
    id: 'all',
    name: 'All locations',
  })
  const [coachFacilities, setCoachFacilities] = useState([])
  const [bookingTypes, setBookingTypes] = useState([])
  const [isPaidTierActive] = useFeatureFlags([flags.FLAG_PAID_TIER_ACTIVE])
  const { enqueueSnackbar } = useSnackbar()
  const snackbarError = useSnackbarError(enqueueSnackbar)
  const [showDisclaimer, setShowDisclaimer] = useState(false)
  const { data, loading } = useQuery(GET_LESSON_TYPES_AND_COACH_FACILITIES)

  useEffect(() => {
    if (loading === false && data) {
      const coachAvailabilities = data.coachAvailabilities || []
      const cf = coachAvailabilities.map(cf => {
        return { id: cf.coachFacility.id, name: cf.coachFacility.name }
      })
      const freeTierLimitsReached = get(
        data,
        'currentUser.attributes.freeTierLimitsReached',
        [],
      )
      const isBookingTypesLimitReached = freeTierLimitsReached.includes(
        'BOOKING_TYPES',
      )
      setBookingTypes(data.lessonTypes)
      setCoachFacilities(cf)
      setSelectedFacility(get(cf, '0.id', null))
      setShouldShowDisclaimer(isPaidTierActive && isBookingTypesLimitReached)
      setShowDisclaimer(isPaidTierActive && isBookingTypesLimitReached)
    }
  }, [loading, data, isPaidTierActive, setShouldShowDisclaimer])

  const successUrl = `${MY_PGA_COM_HOST}/pga-coach/checkout/success`

  const [
    callCreateSubscriptionPaymentSession,
    { loading: loadingSubscription },
  ] = useCreateSubscriptionPaymentSession()

  const handleUpgradeClick = async () => {
    try {
      const { data } = await callCreateSubscriptionPaymentSession({
        variables: { successUrl },
      })
      const {
        url,
        success,
        message,
      } = data?.createPgaCoachSubscriptionPaymentSession
      if (!success) {
        return snackbarError(message)
      }
      if (success && url) {
        window.open(url, '_blank').focus()
      }
    } catch (e) {
      snackbarError(e?.message)
    }
  }

  const BookingTypesViewWrapper = ({ children }) => (
    <>
      <Grid item xs={12}>
        {loadingSubscription && <LinearProgress color="secondary" />}
        <DisclaimerWithTwoCta
          icon={<InfoOutlinedIcon style={{ color: '#554427' }} />}
          description="Enjoy the benefits of your free account with up to two booking types. Want to host more? Upgrade now to unlock unlimited booking types."
          leftCta={
            <Button
              variant="text"
              color="primary"
              onClick={handleUpgradeClick}
              style={{ paddingLeft: 0 }}
            >
              Upgrade Now
            </Button>
          }
          rightCta={
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                setShowDisclaimer(false)
              }}
            >
              Got It
            </Button>
          }
          show={showDisclaimer}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Typography variant="h5">
          <Box fontWeight={500}>Booking types</Box>
        </Typography>
      </Grid>
      <Grid item md={7} xs={12}>
        <Box my={2}>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            Select a facility from the dropdown below to see the booking types
            associated with that facility. You can also create a new booking
            type.
          </Typography>
        </Box>
      </Grid>
      {children}
    </>
  )

  if (loading) {
    return (
      <BookingTypesViewWrapper>
        <LinearProgress />
      </BookingTypesViewWrapper>
    )
  }

  const isEmpty = bookingTypes.length === 0

  const hasAvailabilityConfigured = data.coachAvailabilities.some(
    a => a.timeSlots.length > 0,
  )

  if (isEmpty && hasAvailabilityConfigured) {
    return (
      <EmptyBookingTypes
        actionFn={() => {
          history.push('/pga-coach/bookings/booking-types/new')
        }}
        IconComp={() => <LibraryBooksOutlinedIcon fontSize="large" />}
        title="Create a booking type"
        caption="Take the first step to online customer bookings. A booking type represents a coaching program you offer (e.g. private instruction). Get started today!"
        buttonText="Create Booking Type"
        removeButton={isMobile}
      />
    )
  }

  if (isEmpty && !hasAvailabilityConfigured) {
    return (
      <EmptyBookingTypes
        actionFn={() => {
          history.push('/pga-coach/settings/availability')
        }}
        IconComp={() => <LibraryBooksOutlinedIcon fontSize="large" />}
        title="Create a booking type"
        caption="Before creating a booking type, you must set your availability"
        buttonText="Set Availability"
        removeButton={isMobile}
      />
    )
  }

  return (
    <BookingTypesViewWrapper>
      <Grid item lg={4} md={6} xs={12}>
        <Box className={classes.locationWrapper}>
          <LocationDropdown
            selectedFacility={selectedFacility}
            setSelectedFacility={setSelectedFacility}
            locations={coachFacilities}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <BookingTypesList
          selectedFacility={selectedFacility}
          bookingTypes={bookingTypes}
        />
      </Grid>
    </BookingTypesViewWrapper>
  )
}

export default BookingTypesView
