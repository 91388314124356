import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined'
import YesNoButtons from '../form-components/yes-no-buttons'
import Waitlist from './waitlist'
import { useAuth } from 'lib/auth'
import { flags, useFeatureFlags } from 'lib/feature-flags'

const MaxAttendance = () => {
  const [isYes, setIsYes] = useState(false)
  const { register, errors, watch, setValue, getValues } = useFormContext()
  const { user } = useAuth()
  const [isPaidTierActive] = useFeatureFlags([flags.FLAG_PAID_TIER_ACTIVE])

  const tags = getValues('tags')
  const hasPaidTierSubscription = user?.coach?.hasPaidTierSubscription
  const isPgaHopeEvent = tags?.includes('PGA HOPE')
  const showWaitlistOption = isPaidTierActive
    ? hasPaidTierSubscription || isPgaHopeEvent
    : true

  const maxAttendance = watch('maxAttendance')
  const clearMaxAttendance = () => setValue('maxAttendance', null)

  const handleChange = e => {
    // I want to enforce that the user only enters a positive number
    const regex = /^[0-9]*$/
    // if they paste a letter or a negative number, I want to do nothing
    if (e.target.value === '' || regex.test(e.target.value)) {
      setValue(e.target.value)
    }
  }
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box mb={4} mt={6}>
        <Box mb={2}>
          <Typography variant="h6" align="center" mb={2}>
            Attendance
          </Typography>
        </Box>
        <Typography variant="body" align="center">
          Would you like to limit attendance for this offering?
        </Typography>
      </Box>
      <Box mb={isYes ? 4 : 6}>
        <YesNoButtons {...{ isYes, setIsYes }} dataCy="maxAttendanceToggle" />
      </Box>
      {isYes ? (
        <>
          <Container maxWidth="xs">
            <TextField
              defaultValue={0}
              name="maxAttendance"
              type="number"
              inputRef={register}
              error={!!errors.maxAttendance}
              label="Maximum number of attendees"
              disabled={!isYes}
              onChange={e => handleChange(e)}
              InputProps={{
                inputProps: { min: 0 },
                // I want to enforce that the user only enters a positive number
                min: 0,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={maxAttendance === null || maxAttendance === ''}
                      onClick={clearMaxAttendance}
                    >
                      <ClearOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={
                errors.maxAttendance
                  ? 'This field must be a positive number'
                  : null
              }
              fullWidth
            />
          </Container>
          {/* TODO look for a better implementation for the padding */}
          <Box mb={6} />
          <Waitlist showWaitlist={isYes && showWaitlistOption} />
        </>
      ) : null}
    </Box>
  )
}

export default MaxAttendance
