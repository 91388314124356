import React from 'react'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import FormHelperText from '@material-ui/core/FormHelperText'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { CtrlPhone, CtrlTextField } from 'components/form-util'

const useStyles = makeStyles(theme => ({
  coachAvatar: {
    height: '128px',
    width: '128px',
    fontSize: '2rem',
    [theme.breakpoints.down('sm')]: {
      height: '150px',
      width: '150px',
    },
  },
}))

export const AccountForm = ({ form, coach }) => {
  const classes = useStyles()
  const { control, errors, register } = form

  return (
    <Grid container spacing={3}>
      <Grid
        xs={12}
        md={3}
        item
        container
        alignItems="center"
        justifyContent="center"
      >
        <Avatar
          className={classes.coachAvatar}
          src={coach?.coachProfile?.profilePhoto}
        >
          {coach?.name?.trim().substring(0, 1)}
        </Avatar>
      </Grid>
      <Grid xs={12} md={9} item container alignItems="center">
        <input name="coachId" type="hidden" ref={register()} />
        <Grid item xs={12} md={9}>
          <FormControl>
            <CtrlTextField
              label="Display Name"
              name="name"
              id="name"
              {...{ control, errors }}
            />
            <FormHelperText>
              How your name will appear on program communications and info
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Contact Preferences</Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <FormControl>
          <CtrlTextField
            name="email"
            id="email"
            label="Email Address"
            {...{ control, errors }}
          />
          <FormHelperText>
            Preferred email address for communications
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={7}>
        <CtrlPhone
          name="phone"
          id="phone"
          label="Phone Number"
          {...{ control, errors }}
        />
      </Grid>
    </Grid>
  )
}
