import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    margin: '0 60px 60px',
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      margin: '10px 20px',
    },
  },
  icon: {
    minWidth: '40px',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '12px',
    color: '#00234B',
    fontSize: '32px',
  },
  action: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  tile: {
    backgroundColor: '#F5F7FA',
    borderRadius: '12px',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
    height: '100%',
    boxSizing: 'border-box',
  },
}))

export default useStyles
