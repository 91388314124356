import React, { useState } from 'react'
import {
  useRouteMatch,
  Switch,
  Route,
  Link,
  useLocation,
  Redirect,
} from 'react-router-dom'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'
import ErrorView from 'components/error'
import { PageHeader } from 'components/page-header'
import ApolloDataView from 'components/apollo-data-view'
import CoachAddStudentModal from 'components/add-student-modal/coach-add-student-modal'
import StudentListView from './student-list-new-view'
import EmptyListView from './empty-list-view'
import LeadsQuery from './leads-query'
import LoadingView from './loading-view'
import { DateTime } from 'luxon'
import {
  useGetStudents,
  useGetLeads,
  useGetUpcomingSessions,
} from './api-hooks'

const StudentsPage = React.memo(({ setUnviewedLeadCount }) => {
  const [open, setOpen] = useState(false)
  const { path } = useRouteMatch()
  const location = useLocation()
  const { loading, error, data, refetch } = useGetStudents()

  const {
    loading: leadsLoading,
    error: leadsError,
    data: leadsData,
    refetch: leadsRefetch,
  } = useGetLeads()

  const upcomingBookingsDateRange = {
    from: DateTime.now()
      .startOf('day')
      .toISO(),
    to: null,
  }

  const {
    loading: sessionsLoading,
    error: sessionsError,
    data: sessionsData,
    refetch: sessionsRefetch,
  } = useGetUpcomingSessions(upcomingBookingsDateRange, 'ASC')

  const unviewedLeadsCount =
    leadsData &&
    leadsData.coachingInquiries?.filter(lead => lead.viewedAt === null).length
  const pageLoading = loading || leadsLoading || sessionsLoading
  const isLeadsPage = !!location.pathname.match(/leads/)

  const tabs = (
    <Tabs value={isLeadsPage ? 'leads' : 'students'}>
      <Tab
        label="Students"
        value="students"
        component={Link}
        to={`${path}/list`}
      />
      <Tab
        label={`Leads ${
          unviewedLeadsCount > 0 ? `(${unviewedLeadsCount})` : ''
        }`}
        value="leads"
        component={Link}
        to={`${path}/leads`}
      />
    </Tabs>
  )

  return (
    <>
      <PageHeader
        title="Students"
        tabs={tabs}
        actions={
          isLeadsPage ? null : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(!open)}
            >
              Create Student
            </Button>
          )
        }
        fixedMobileActions
      />
      <Switch>
        <Route exact path={`${path}`}>
          <Redirect to={`${path}/list`} />
        </Route>
        <Route
          path={`${path}/list`}
          exact
          component={() => (
            <ApolloDataView
              error={error || sessionsError}
              loading={pageLoading}
              data={data?.coachStudents}
            >
              {{
                errorView: <ErrorView />,
                emptyView: <EmptyListView setOpen={setOpen} />,
                loadingView: <LoadingView />,
                dataView: students => (
                  <StudentListView
                    searchableItems={students}
                    students={students}
                    sessions={sessionsData}
                    refetchStudents={refetch}
                    refetchSessions={sessionsRefetch}
                  />
                ),
              }}
            </ApolloDataView>
          )}
        />
        <Route
          path={`${path}/leads/:leadId?`}
          exact
          component={() => (
            <LeadsQuery
              {...{
                data: leadsData?.coachingInquiries,
                freeTierLimitsReached:
                  leadsData?.currentUser?.attributes?.freeTierLimitsReached,
                error: leadsError,
                loading: pageLoading,
                refetch: leadsRefetch,
                studentRefetch: refetch,
                setUnviewedLeadCount,
              }}
            />
          )}
        />
      </Switch>
      <CoachAddStudentModal {...{ open, setOpen, onAdd: refetch }} />
    </>
  )
})

export default StudentsPage
