import React from 'react'
import LegalDoc from './legal-doc'

export default function JRLCoachCodeOfConduct() {
  return (
    <LegalDoc>
      <h2>Code of Conduct</h2>
      In addition to complying with any federal or state laws that may be
      applicable to your hosting an event in the PGA Jr. League program, you
      agree to comply with the following “Code of Conduct” which may, as
      determined by The Professional Golfers’ Association of America (also
      hereinafter referred to as “PGA of America”), be updated from time to
      time. Any updates to this Code of Conduct will be communicated to all
      hosts of PGA Jr. League programs and will become effective thirty (30)
      days following such initial dissemination.
      <h3>General Program Concepts</h3>
      When participating as a host Professional, individuals shall:
      <ul>
        <li>
          Be a positive role model to your players; display emotional maturity
          and be alert to the physical safety of players.
        </li>
        <li>
          Adjust to the personal needs and problems of players; be a good
          listener; never verbally or physically abuse a player, official, or
          volunteer.
        </li>
        <li>
          Maintain an open line of communication with your teams. Explain the
          goals and objectives of the PGA Jr. League program.
        </li>
      </ul>
      <h3>Coach Requirements</h3>
      All PGA Jr. League Coaches agree to comply with the following program
      requirements, which apply to both regular season and postseason (i.e.,
      “all-star’) teams:
      <ul>
        <li>
          Every PGA Jr. League team must be registered and overseen by a Coach.
        </li>
        <li>
          Coaches must be (1) a PGA Professional, (2) a PGA Associate or
          Student, (3) a LPGA Professional, or (4) a Member of a sanctioned PGA
          organization, as defined by PGA, IN GOOD STANDING with your respective
          Association.
        </li>
        <li>
          Never verbally or physically abuse a player or official; give all
          players the opportunity to improve their skills, gain confidence and
          develop self-esteem; teach them the basics.
        </li>
        <li>
          All Coaches, Assistant Coaches and Volunteers must register and
          successfully complete a PGA defined criminal background screen process
          and abuse prevention training prior to being in contact with players
          and complete any additional registration requirements established by
          PGA.
        </li>
        <li>
          There shall be at least one authorized Coach, Assistant Coach or
          Volunteer per team of players at each PGA Jr. League game.
        </li>
        <li>
          Transportation of PGA Jr. League players by Coaches, Assistant Coaches
          and/or Volunteers is forbidden unless the Coach/Coach/Volunteer is the
          parent, guardian or sibling of the applicable player.
        </li>
        <li>
          As directed by Coaches, Assistant Coaches are required to inform
          spectators on the expectation of positive behavior and take
          appropriate action if violations occur.
        </li>
        <li>
          If an issue arises, Coaches are expected to discuss with their Coach
          colleagues and resolve it amongst themselves. In the event any issue
          remains unresolved, the applicable Coaches will bring such issue
          forward to the PGA Competitions Committee via the Player Engagement
          Consultant for resolution, with the determination of the PGA
          Competitions Committee being final.
        </li>
        <li>
          Inappropriate touching between a PGA Jr. League player and a Coach,
          Assistant Coach and/or Volunteer is prohibited.
        </li>
        <li>
          Use of audio or visual recording devices, including a cell phone
          camera, is not allowed in changing areas, rest rooms or locker rooms.
        </li>
        <li>
          It is every Coach’s responsibility to promptly report any incident
          regarding sexual misconduct by any party involved in the PGA Jr.
          League program to a member of PGA’s staff or to the hotline
          established by PGA. Reporting must occur when an individual has
          firsthand knowledge of misconduct or where specific and credible
          information has been received from a victim or knowledgeable third
          party. Various state laws may also require reporting to law
          enforcement or to a designated child protection agency. Any other
          violation of the PGA Jr. League Code of Conduct shall be reported to
          the applicable PGA Jr. League Regional Manager.
        </li>
        <li>
          Professionals are expected to have an in-depth knowledge and
          understanding of the PGA Jr. League format, policies and rules.
        </li>
      </ul>
      <h3>Additional Guidelines</h3>
      The Additional Guidelines below describe strategies for creating an open
      and observable environment, establishing clear boundaries between adults
      and players, and adhering to the spirit of PGA Jr. League. These
      guidelines must always be followed by Coaches, Assistant Coaches, and
      Volunteers at all times.
      <ul>
        <li>
          Respect the principles and rules of the game and adhere to the
          expectations of proper conduct and fair play associated with the game
          and PGA Jr. League.
        </li>
        <li>
          Players participating in the All-Star Season as All-Stars must play in
          a local regular season program that is within 75 driving miles of
          their residence. &nbsp;This mileage policy is in addition to the
          existing eligibility requirements around league age and active PGA Jr.
          League participation. &nbsp;More information about All-Star
          eligibility can be found{' '}
          <a
            href="https://www.google.com/url?q=https://docs.google.com/document/d/1EjF2oZjStQfqa3iWq7VbzxhG0pRxKhLhny2wa8P4TCE/edit&amp;sa=D&amp;source=editors&amp;ust=1707126388973638&amp;usg=AOvVaw1_KTfA4iMiPymDDp5GJjro"
            target="_blank"
            rel="noreferrer"
          >
            HERE
          </a>
        </li>
        <li>
          All PGA Jr. League practices shall be open to observation by parents.
        </li>
        <li>
          An open and observable environment shall be maintained for all
          interactions between adults and players. Private, or one-on-one
          situations, shall be avoided unless they are open and observable.
        </li>
        <li>
          Relationships of a peer-to-peer nature with any players shall be
          avoided.
        </li>
        <li>
          Coaches, Assistant Coaches and Volunteers, shall avoid horseplay and
          roughhousing with players.
        </li>
        <li>
          Assistant Coaches and Volunteers to only instruct at the direction of
          the Coach.
        </li>
        <li>
          When a Coach, Assistant Coach, or Volunteer touches a player as part
          of instruction, the Coach, Assistant Coach or Volunteer shall do so in
          direct view of others and inform the player of what he/she is doing
          prior to the initial contact. Touching players as part of their
          instruction shall be minimized outside the boundaries of what is
          considered normal instruction.
        </li>
        <li>
          All Coaches, Assistant Coaches and Volunteers shall refrain from the
          use of inappropriate language and engaging in any activity deemed
          inappropriate by PGA (e.g., use of alcohol/tobacco during PGA Jr.
          League activities, etc.).
        </li>
        <li>
          All Coaches, Assistant Coaches and Volunteers must never place the
          value of winning above the value of good sportsmanship and character.
        </li>
        <li>
          The PGA Jr. League National Committee is supportive of these
          guidelines.
        </li>
      </ul>
      The PGA of America reserves the right to remove, suspend, and/or
      permanently preclude any Coach, Assistant Coach, and/or Volunteer from a
      competition (or any other PGA Jr. League related activity) for violations
      of this “Code of Conduct”. I understand that there will be zero tolerance
      for Coaches, Assistant Coaches and Volunteers who intimidate or physically
      or verbally abuse players, officials, or participants at any PGA Jr.
      League event. Further, I understand that myself and any other coach or
      assistant coach on my team found guilty of improper conduct may be asked
      to leave any PGA Jr. League event and may be suspended or dismissed by The
      Professional Golfers’ Association of America in its sole discretion.
    </LegalDoc>
  )
}
