import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { withProtectedRoute, roles } from 'lib/auth'
import { ThemeProvider, makeStyles } from '@material-ui/core/styles'
import themes, { extendTheme } from 'themes'
import IconButton from '@material-ui/core/IconButton'
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined'
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined'
import Button from '@material-ui/core/Button'
import CoachAppbar from 'components/coach-app-bar'
import { STRIPE_CUSTOMER_PORTAL_URL } from 'env'

const useStyles = makeStyles(theme => ({
  successIcon: {
    background: 'rgba(0, 35, 75, 0.08)',
    padding: '16px',
    marginBottom: theme.spacing(1.5),
    '&:hover': {
      background: 'rgba(0, 35, 75, 0.08)',
    },
    '& svg': {
      color: theme.palette.blue.dark,
    },
  },
  textWrapper: {
    maxWidth: '656px',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
}))

const CheckoutSuccessPage = () => {
  const styles = useStyles()

  return (
    <ThemeProvider theme={extendTheme(themes.pgaCoach)}>
      <CoachAppbar position="relative" hideContactButton />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={[10, 12]}
        px={3}
      >
        <IconButton className={styles.successIcon}>
          <CelebrationOutlinedIcon fontSize="large" />
        </IconButton>
        <Typography
          variant="h4"
          style={{ marginBottom: '16px', fontWeight: '500' }}
        >
          Success!
        </Typography>
        <Typography variant="body1" className={styles.textWrapper}>
          You have successfully subscribed to PGA Coach+! You will receive an
          email confirmation shortly. To manage your subscription, visit Stripe.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<OpenInNewOutlinedIcon />}
          href={STRIPE_CUSTOMER_PORTAL_URL}
          target="_blank"
        >
          Manage Subscription
        </Button>
        <Button
          color="primary"
          href={'/pga-coach'}
          style={{ marginTop: '20px' }}
        >
          Go Home
        </Button>
      </Box>
    </ThemeProvider>
  )
}

export default withProtectedRoute(CheckoutSuccessPage, roles.COACH)
