import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  actionBar: {
    minWidth: '215px',
    [theme.breakpoints.only('xs')]: {
      zIndex: '10',
      display: 'flex',
      width: '100%',
      position: 'fixed',
      bottom: 0,
      left: 0,
      background: '#fff',
      padding: '12px 12px 24px 0',
      justifyContent: 'space-around',
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      '& button': {
        width: '90%',
      },
    },
  },
  mainWrapper: {
    margin: theme.spacing(7),
    marginTop: theme.spacing(4),
    [theme.breakpoints.only('xs')]: {
      margin: '16px 16px 96px 16px',
    },
  },
  actionBlock: {
    display: 'flex',
    flexDirection: 'column-reverse',
    marginBottom: theme.spacing(4),
    width: '60%',
    '& a': {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  formField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  single: ({ isLessonPacks }) => ({
    background: isLessonPacks ? '#fff' : 'rgba(0, 35, 75, 0.08)',
    '& svg:last-child': {
      visibility: isLessonPacks ? 'hidden' : 'visible',
    },
  }),
  lessonPacks: ({ isLessonPacks }) => ({
    background: isLessonPacks ? 'rgba(0, 35, 75, 0.08)' : '#fff',
    '& svg:last-child': {
      visibility: isLessonPacks ? 'visible' : 'hidden',
    },
  }),
  checkIcon: {
    position: 'relative',
    left: '-45%',
  },
  lessonTypeWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
    marginBottom: theme.spacing(4),
    '& h6': {
      marginTop: theme.spacing(1),
      textTransform: 'uppercase',
    },
    '& div': {
      width: '43%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'space-between',
      border: '1px solid #E0E0E0',
      borderRadius: '4px',
      padding: '16px 12px',
      minHeight: '168px',
      cursor: 'pointer',
    },
  },
  locationWrapper: {
    marginBottom: theme.spacing(2),
    '& h6': {
      fontSize: '1rem',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  disabledButton: {
    background: '#0000001F',
    color: '#00000042',
    '&:hover': {
      background: '#0000001F',
    },
  },
}))

export default useStyles
