import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useWizardFormContext } from 'components/wizard-form/context'
import { gtmEvent } from 'lib/gtm'
import CircularHighlightedIcon from 'components/circular-highlighted-icon'
import CreateFromTemplateDialog from 'components/group-events/create-group-event-form-wizard/dialogs/create-from-template-dialog'
import CopyExistingEventDialog from 'components/group-events/create-group-event-form-wizard/dialogs/copy-existing-event-dialog'
import { useHydrateEventFormValues } from 'components/group-events/create-group-event-form-wizard/hooks'

export const useStyles = makeStyles(theme => ({
  actionTileWrapper: {
    width: '375px',
    margin: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 35, 75, 0.04)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  headlineText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      margin: '12px',
    },
  },
}))

const TileButtons = ({
  icon,
  headlineText,
  captionText,
  buttonText,
  onClick,
}) => {
  const classes = useStyles()
  return (
    <Card
      variant="outlined"
      className={classes.actionTileWrapper}
      onClick={() => {
        gtmEvent({
          event: 'formSubmit',
          formCategory: 'group-event-creation',
          formAction: headlineText.toLowerCase().replace(/\s+/g, '-'),
        })
        onClick && onClick()
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        p={2}
      >
        <CircularHighlightedIcon icon={icon} />
        <Box my={0.5}>
          <Typography variant="h6">{headlineText}</Typography>
        </Box>
        <Box mb={3} textAlign="center">
          <Typography variant="body2" color="textSecondary">
            {captionText}
          </Typography>
        </Box>
        <Button variant="contained" color="primary" onClick={onClick}>
          {buttonText}
        </Button>
      </Box>
    </Card>
  )
}

const GetStarted = ({ canBuildOwnEvent }) => {
  const { advance } = useWizardFormContext()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  const hydrateEventFormValues = useHydrateEventFormValues()

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.search.includes('preload') && hydrateEventFormValues()
    }, 250)
    return () => clearTimeout(timer)
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box mb={4}>
        <Typography variant="h4" align="center" data-cy="get-started-heading">
          How would you like to create your event?
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        mb={4}
        flexDirection={!isMobile ? 'row' : 'column'}
      >
        <CreateFromTemplateDialog
          trigger={
            <TileButtons
              icon={LibraryBooksOutlinedIcon}
              headlineText="Create from Template"
              captionText="Modify an existing group event template"
              buttonText="Create from template"
            />
          }
        />
        <CopyExistingEventDialog
          trigger={
            <TileButtons
              icon={FileCopyOutlinedIcon}
              headlineText="Copy Existing Event"
              captionText="Copy and edit an existing event"
              buttonText="Copy existing event"
            />
          }
          isFreeTierReached={!canBuildOwnEvent}
        />
        {canBuildOwnEvent && (
          <TileButtons
            onClick={advance}
            icon={BuildOutlinedIcon}
            headlineText="Build Your Own"
            captionText="Customize your own group event"
            buttonText="Build your own"
          />
        )}
      </Box>
    </Box>
  )
}

export default GetStarted
