import { useEffect } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

const show = () => {
  if (window?.Intercom?.booted) {
    window.Intercom('show')
  } else {
    window.open('mailto:support@pga.com')
  }
}

const showNewMessage = content => {
  if (window?.Intercom?.booted) {
    window.Intercom('showNewMessage', content)
  } else {
    window.open(`mailto:support@pga.com?body=${content}`)
  }
}

const startSurvey = surveyId => {
  if (window?.Intercom?.booted) {
    window.Intercom('startSurvey', surveyId)
  } else {
    window.open('mailto:support@pga.com')
  }
}

const hideLauncher = () => {
  if (window?.Intercom?.booted) {
    window.Intercom('update', { hide_default_launcher: true })
  }
}

const showLauncher = () => {
  if (window?.Intercom?.booted) {
    window.Intercom('update', { hide_default_launcher: false })
  }
}

const useHideIntercom = () => {
  useEffect(() => {
    hideLauncher()

    return () => showLauncher()
  })
}

const removeLauncher = () => {
  if (window?.Intercom?.booted) {
    window.Intercom('shutdown')
  }
}

const bootLauncher = () => {
  if (window?.Intercom?.booted) {
    window.Intercom('boot')
  }
}

const useRemoveIntercom = () => {
  useEffect(() => {
    hideLauncher()
    removeLauncher()
    return () => {
      bootLauncher()
      showLauncher()
    }
  })
}

const useHideMobileIntercom = (shouldHide = true) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'))

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    // Wait for the launcher to load (max 30 seconds).
    // Need to wait for the launcher to load in order to apply styles, otherwise
    // there is an error that prevents page loads.
    const timeout = setTimeout(() => clearInterval(interval), 30000)
    const interval = setInterval(() => {
      if (window?.Intercom?.booted) {
        // Add class to show the launcher
        if (isMobile && shouldHide) {
          hideLauncher()
        } else {
          showLauncher()
        }

        clearInterval(interval)
        clearTimeout(timeout)
      }
    }, 100)
  }, [isMobile])
  /* eslint-disable react-hooks/exhaustive-deps */
}

// Move Intercom button above action bars on mobile only.
const useMobileButtonSafeIntercom = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'))

  useEffect(() => {
    if (isMobile) {
      const launcher = document.querySelector(
        '.intercom-lightweight-app-launcher',
      )

      if (launcher) {
        launcher.style.bottom = '70px'
      }

      return () => {
        const launcher = document.querySelector(
          '.intercom-lightweight-app-launcher',
        )

        if (launcher) {
          launcher.style.bottom = '30px'
        }
      }
    }
  }, [isMobile])
}

// Move Intercom button above action bars on always.
const useButtonSafeIntercom = () => {
  useEffect(() => {
    const launcher = document.querySelector(
      '.intercom-lightweight-app-launcher',
    )

    if (launcher) {
      launcher.style.bottom = '70px'
    }

    return () => {
      const launcher = document.querySelector(
        '.intercom-lightweight-app-launcher',
      )

      if (launcher) {
        launcher.style.bottom = '30px'
      }
    }
  })
}

export {
  show,
  showNewMessage,
  useHideIntercom,
  useRemoveIntercom,
  useHideMobileIntercom,
  startSurvey,
  useMobileButtonSafeIntercom,
  useButtonSafeIntercom,
}
