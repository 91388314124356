import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  downloadButton: {
    marginRight: '.5rem',
    borderColor: '#00234B80',
    color: '#043362',
  },
  editButton: {
    marginRight: '0',
  },
  pageHeader: {
    borderBottom: '0 !important',
  },
  tabContainer: {
    maxWidth: '100%',
    padding: '0 60px',
    borderBottom: '1px solid #e0e0e0',
    [theme.breakpoints.down('sm')]: {
      padding: '0 24px',
    },
  },
  tabPanel: {
    minHeight: '100%',
    padding: '24px 60px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 24px',
    },
    '& > div': {
      width: '100%',
    },
  },
  mobileActions: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default useStyles
