import React from 'react'
import { CSVLink } from 'react-csv'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Link from '@material-ui/core/Link'
import RecordBookingPaymentDialog from 'components/record-booking-payment-dialog'
import AccountRouterLink from 'components/account-router-link'
import { formatPhoneNumber } from 'utils/phoneNumber'
import { useStyles } from './styles'
import { toFullDateAndTime } from 'utils/dateUtils'
import { centsToDollars } from 'lib/utils/number'
import CancelEventRegistrationDialog from 'components/cancel-event-registration-dialog'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import PaymentIcon from '@material-ui/icons/Payment'
import StreamSendMessageButton from 'components/stream-messaging/send-message-button'
import MessageOutlined from '@material-ui/icons/MessageOutlined'
import { useRouteMatch, Link as LinkTo } from 'react-router-dom'
import SensitiveInfoWrapper from 'lib/auth/SensitiveInfoWrapper'
import Waitlist from './waitlist'
import { useAuth } from 'lib/auth'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { generateParticipantCsvData, getRsvpStatus } from './utils'
import themes from 'themes'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import Badge from '@material-ui/core/Badge'
import TextField from '@material-ui/core/TextField'
import { flags, useFeatureFlags } from 'lib/feature-flags'
import AttendanceModal from '../attendance-modal'

const HopeLinkWrapper = ({ condition, wrapper, children }) => {
  return condition ? wrapper(children) : children
}

export const PaymentDetails = ({
  paymentDate,
  registrationPaymentId,
  showLink = false,
}) => {
  const paymentText = `Paid on ${toFullDateAndTime(paymentDate)}`

  if (showLink) {
    return (
      <Link
        component={AccountRouterLink}
        to={`/account/payments/transactions/${registrationPaymentId}`}
        variant="caption"
      >
        {paymentText}
      </Link>
    )
  } else {
    return <Typography variant="caption">{paymentText}</Typography>
  }
}

const ParticipantsList = ({ event, enrollments, isHopeEvent = false }) => {
  const {
    title,
    startDateTime,
    priceInCents,
    acceptsOnlinePayments,
    slug,
  } = event
  const classes = useStyles()
  const { path } = useRouteMatch()

  const { user } = useAuth()
  const isUserPrimaryCoach = user?.id === event.coach.id

  const URL = `${path
    .split('/')
    .slice(0, 2)
    .join('/')}/${slug}`
  return (
    <Box className={classes.studentContent} width="100%">
      {enrollments.map(enrollment => {
        const { student, createdAt, isCanceled } = enrollment
        const transactions = event.sessions[0].enrollments.find(
          e => e.student.id === student.id,
        )?.transactions

        const productCount =
          transactions?.[0]?.registrationPayment?.products?.length || 1
        const studentInitials = `${student.firstName.substring(
          0,
          1,
        )}${student.lastName.substring(0, 1)}`
        const studentName = `${student.firstName} ${student.lastName}`
        const isPaid = transactions.some(t => !t.refund)
        const paymentAmount =
          (isPaid &&
            centsToDollars(transactions[0].amountInCents / productCount)) ||
          0
        const paymentDate = isPaid && transactions[0].createdAt
        const registrationPaymentId =
          isPaid && transactions[0].registrationPayment.id
        const contactInformation = [
          ...new Set(
            student.representatives
              .flatMap(r => [
                r.contact.email,
                formatPhoneNumber(r.contact.phone),
              ])
              .filter(s => s != null && s.trim().length > 0),
          ),
        ].join(' | ')
        const rsvpStatusIcon = getRsvpStatus(enrollment, event)
        return (
          <Box
            key={enrollment.id}
            display="flex"
            alignItems="flex-start"
            mb={2}
          >
            <HopeLinkWrapper
              condition={isHopeEvent}
              wrapper={children => (
                <LinkTo
                  to={`${URL}/${student.id}`}
                  style={{
                    textDecoration: 'none',
                    color: 'rgba(0, 0, 0, 0.87)',
                  }}
                >
                  {children}
                </LinkTo>
              )}
            >
              <Badge
                invisible={!rsvpStatusIcon}
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                badgeContent={rsvpStatusIcon}
                className={classes.avatarWrapper}
              >
                <Avatar>{studentInitials}</Avatar>
              </Badge>
            </HopeLinkWrapper>
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="space-between"
              flexGrow={1}
            >
              <Box>
                <HopeLinkWrapper
                  condition={isHopeEvent}
                  wrapper={children => (
                    <LinkTo
                      to={`${URL}/${student.id}`}
                      style={{
                        textDecoration: 'none',
                        color: 'rgba(0, 0, 0, 0.87)',
                      }}
                    >
                      {children}
                    </LinkTo>
                  )}
                >
                  <Typography variant="body1" style={{ fontWeight: 500 }}>
                    {studentName}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    {contactInformation}
                  </Typography>
                </HopeLinkWrapper>
                <Typography variant="caption" display="block">
                  Registered {toFullDateAndTime(createdAt)}
                  &nbsp;|&nbsp;
                  {isPaid ? (
                    <PaymentDetails
                      paymentAmount={paymentAmount}
                      paymentDate={paymentDate}
                      registrationPaymentId={registrationPaymentId}
                      showLink={isUserPrimaryCoach}
                    />
                  ) : (
                    <Typography variant="caption">Not Paid</Typography>
                  )}
                </Typography>
              </Box>
              <Box>
                {!isPaid && !isCanceled && (
                  <SensitiveInfoWrapper>
                    <RecordBookingPaymentDialog
                      trigger={
                        <Tooltip title="Mark as Paid">
                          <IconButton>
                            <PaymentIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      }
                      enrollment={enrollment}
                      eventTitle={title}
                      eventDate={startDateTime}
                      participantName={studentName}
                      defaultAmountInCents={priceInCents}
                      acceptOnlinePayments={acceptsOnlinePayments}
                    />
                  </SensitiveInfoWrapper>
                )}
                {!isCanceled && (
                  <>
                    <CancelEventRegistrationDialog
                      trigger={
                        <Tooltip title="Cancel Event Registration">
                          <IconButton>
                            <DeleteOutlinedIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      }
                      studentId={student.id}
                      eventId={event.id}
                      acceptsOnlinePayments={acceptsOnlinePayments}
                    />
                  </>
                )}
              </Box>
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

const EventParticipants = ({ setIsOpen, event }) => {
  const { tags } = event
  const classes = useStyles()
  const [isRsvpEnabled] = useFeatureFlags([flags.FLAG_FEAT_GROUP_EVENT_RSVP])

  const canceledEnrollments = event.enrollments.filter(e => e.isCanceled)
  const enrollments = event.enrollments.filter(e => !e.isCanceled)
  const representatives = enrollments.flatMap(e => e.student.representatives)
  const contactExternalIds = representatives.map(rep => rep.contact.externalId)
  const isHopeEvent = tags.includes('PGA HOPE')
  const waitlistCount = event?.waitlist?.entries.length
  const showWaitlist = waitlistCount > 0

  const exportOptions = [{ value: 'attendees', label: 'EXPORT ATTENDEES' }]

  canceledEnrollments.length > 0 &&
    exportOptions.push({
      value: 'canceled attendees',
      label: 'EXPORT CANCELED ATTENDEES',
    })

  showWaitlist &&
    exportOptions.push({
      value: 'waitlist',
      label: 'EXPORT WAITLIST',
    })

  return (
    <DialogContent>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        className={classes.backButtonWrapper}
      >
        <Box
          display="flex"
          alignItems="center"
          onClick={() => setIsOpen('detail')}
        >
          <ArrowBackIosIcon fontSize="small" />
          <Typography variant="caption" style={{ fontWeight: 900 }}>
            Back
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <SensitiveInfoWrapper>
            <StreamSendMessageButton
              userIds={contactExternalIds}
              component={IconButton}
              color="primary"
            >
              <MessageOutlined />
            </StreamSendMessageButton>
          </SensitiveInfoWrapper>
          <ThemeProvider theme={createTheme(themes.pgaCoach)}>
            <FormControl variant="outlined">
              <TextField
                id="export"
                select
                label="Export"
                classes={{ root: classes.exportSelect }}
                value=""
              >
                {exportOptions.map(option => (
                  <MenuItem value={option.value} key={option.value}>
                    <Button
                      component={CSVLink}
                      data={generateParticipantCsvData(
                        event.enrollments,
                        event?.waitlist?.entries,
                        isHopeEvent,
                        option.value === 'waitlist',
                        option.value === 'canceled attendees',
                      )}
                      filename={`${event.title}-${event.startDateTime}-${option.value}.csv`}
                      color="primary"
                      classes={{ root: classes.exportButton }}
                      onClick={() => setIsOpen('detail')}
                    >
                      {option.label}
                    </Button>
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </ThemeProvider>
          {isRsvpEnabled && event?.collectAttendance && event?.currentSession && (
            <AttendanceModal
              trigger={
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: '10px' }}
                >
                  Attendance
                </Button>
              }
              event={event}
            />
          )}
        </Box>
      </Box>
      <Box display="flex" alignItems="flex-start" flexDirection="column">
        <Typography variant="h6" className={classes.titleFont}>
          {event.title}
        </Typography>
        <Typography variant="h4" className={classes.headerFont}>
          Attendees ({enrollments.length})
        </Typography>
        <ParticipantsList
          event={event}
          enrollments={enrollments}
          isHopeEvent={isHopeEvent}
        />
        {canceledEnrollments.length > 0 && (
          <>
            <hr />
            <Typography variant="h6" className={classes.headerFont}>
              Canceled Attendees ({canceledEnrollments.length})
            </Typography>
            <ParticipantsList
              event={event}
              enrollments={canceledEnrollments}
              isHopeEvent={isHopeEvent}
            />
          </>
        )}
        {showWaitlist && (
          <>
            <hr />
            <Typography variant="h6" className={classes.headerFont}>
              Waitlist ({waitlistCount})
            </Typography>
            <Waitlist contacts={event.waitlist.entries} />
          </>
        )}
      </Box>
    </DialogContent>
  )
}

export default EventParticipants
