import React, { useState, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { PageHeader } from 'components/page-header'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Switch from '@material-ui/core/Switch'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import useStyles from './styles'
import EventsPageData from './event-components/events-show-page'
import { GET_PAST_GROUP_EVENTS, GET_UPCOMING_GROUP_EVENTS } from './query'
import { GROUP_EVENT_PAGE_LIMIT } from 'lib/pga-dot-coach'
import { useQuery } from '@apollo/client'
import { DateTime } from 'luxon'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from 'components/error'
import { RefetchContext } from 'lib/utils/refetchContext'
import CreatePgaHopeEventDialog from './create-pga-hope-event-dialog'

const EventsPage = () => {
  const [selectedTab, setSelectedTab] = useState('inProgress')
  const [showInProgressTab, setShowInProgressTab] = useState(true)
  const [showCanceledEvents, setShowCanceledEvents] = useState(false)
  const [offset, setOffset] = useState(0)
  const classes = useStyles()
  const { path } = useRouteMatch()
  const history = useHistory()
  const [shouldDisableCreateEvent, setShouldDisableCreateEvent] = useState(
    false,
  )
  const [showLimitReachedDisclaimer, setShowLimitReachedDisclaimer] = useState(
    false,
  )
  const [
    openCreatePgaHopeEventDialog,
    setOpenCreatePgaHopeEventDialog,
  ] = useState(false)

  useEffect(() => {
    setOffset(0)
  }, [selectedTab, showCanceledEvents])

  const showPastEvents = selectedTab === 'past'
  const startDateTime = showPastEvents
    ? {
      from: null,
      to: DateTime.local()
        .startOf('day')
        .toISO(),
    }
    : {
      from: DateTime.now()
        .startOf('day')
        .toISO(),
      to: null,
    }

  const pastEventsVariables = {
    orderBy: { field: 'START_DATE_TIME', direction: 'DESC' },
    startDateTime: startDateTime,
    includeCanceled: showCanceledEvents,
    limit: GROUP_EVENT_PAGE_LIMIT,
    offset: offset,
    skip: selectedTab !== 'past',
  }

  const upcomingEventsVariables = {
    orderBy: { field: 'START_DATE_TIME', direction: 'ASC' },
    startDateTime: startDateTime,
    includeCanceled: showCanceledEvents,
    limit: GROUP_EVENT_PAGE_LIMIT,
    offset: offset,
    skip: selectedTab === 'past',
  }

  const useGroupEventsQuery = (query, variables, skip) => {
    return useQuery(query, {
      variables: variables,
      skip: skip,
    })
  }

  const pastEventsResult = useGroupEventsQuery(
    GET_PAST_GROUP_EVENTS,
    pastEventsVariables,
    selectedTab !== 'past',
  )
  const upcomingEventsResult = useGroupEventsQuery(
    GET_UPCOMING_GROUP_EVENTS,
    upcomingEventsVariables,
    selectedTab === 'past',
  )

  const queryResult =
    selectedTab === 'past' ? pastEventsResult : upcomingEventsResult

  const handleCreateEventClick = (
    shouldDisableCreateEvent,
    showLimitReachedDisclaimer,
  ) => {
    if (shouldDisableCreateEvent && showLimitReachedDisclaimer) return
    if (!shouldDisableCreateEvent && showLimitReachedDisclaimer) {
      setOpenCreatePgaHopeEventDialog(true)
      return
    }
    history.push('/pga-coach/bookings/events/new')
  }

  const { data, loading, error, refetch } = queryResult

  const crumbs = [
    {
      label: 'Offerings',
      to: [...path.split('/').slice(0, 3), 'offerings'].join('/'),
    },
  ]
  return (
    <>
      <PageHeader
        title="Events"
        crumbs={crumbs}
        actions={
          <Tooltip
            title={
              shouldDisableCreateEvent
                ? 'To add another offering, you need to upgrade your PGA Coach account.'
                : ''
            }
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                handleCreateEventClick(
                  shouldDisableCreateEvent,
                  showLimitReachedDisclaimer,
                )
              }
              className={shouldDisableCreateEvent ? classes.disabledButton : ''}
            >
              Create Event
            </Button>
          </Tooltip>
        }
        tabs={
          <Container className={classes.containerStyles}>
            <Box className={classes.tabWrapper}>
              <Tabs
                value={selectedTab}
                onChange={(_, newValue) => setSelectedTab(newValue)}
              >
                {showInProgressTab && (
                  <Tab label="In Progress" value="inProgress" />
                )}
                <Tab label="Upcoming" value="upcoming" />
                <Tab label="Past" value="past" />
              </Tabs>
              <Box>
                <FormControlLabel
                  className={classes.showCancellations}
                  size="size"
                  control={
                    <Switch
                      checked={showCanceledEvents}
                      onChange={e => setShowCanceledEvents(e.target.checked)}
                    />
                  }
                  label={
                    <Typography variant="button">Show Cancellations</Typography>
                  }
                />
              </Box>
            </Box>
          </Container>
        }
        fixedMobileActions
      />
      {loading && <LinearProgress color="primary" />}
      {error && <Error />}
      {!loading && !error && (
        <RefetchContext.Provider value={refetch}>
          <EventsPageData
            eventsData={data}
            selectedTab={selectedTab}
            setShowInProgressTab={setShowInProgressTab}
            setSelectedTab={setSelectedTab}
            showCanceledEvents={showCanceledEvents}
            offset={offset}
            setOffset={setOffset}
            setShouldDisableCreateEvent={setShouldDisableCreateEvent}
            showLimitReachedDisclaimer={showLimitReachedDisclaimer}
            setShowLimitReachedDisclaimer={setShowLimitReachedDisclaimer}
          />
          <CreatePgaHopeEventDialog
            open={openCreatePgaHopeEventDialog}
            setOpen={setOpenCreatePgaHopeEventDialog}
          />
        </RefetchContext.Provider>
      )}
    </>
  )
}

export default EventsPage
