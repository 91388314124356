import React, { useState, useEffect, useContext } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import { get } from 'lodash'
import { useHistory } from 'react-router-dom'
import { parseEvents } from 'utils/eventUtils'
import {
  ADMTrainingExternalLink,
  GROUP_EVENT_PAGE_LIMIT,
} from 'lib/pga-dot-coach'
import { red } from '@material-ui/core/colors'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Button from '@material-ui/core/Button'
import EventsDisplaySelection from './events-display-selection'
import { RefetchContext } from 'lib/utils/refetchContext'
import { flags, useFeatureFlags } from 'lib/feature-flags'
import { useSnackbar, useSnackbarError } from 'lib/snackbar'
import { MY_PGA_COM_HOST } from 'env'
import DisclaimerWithTwoCta from 'components/disclaimer-with-two-cta'
import { useAuth } from 'lib/auth'
import { useCreateSubscriptionPaymentSession } from 'lib/graphql/mutations/create-subscription-payment-session'

const EventsPageData = ({
  eventsData,
  selectedTab,
  setSelectedTab,
  showCanceledEvents,
  offset,
  setOffset,
  setShowInProgressTab,
  setShouldDisableCreateEvent,
  showLimitReachedDisclaimer,
  setShowLimitReachedDisclaimer,
}) => {
  const [events, setEvents] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [lastPage, setLastPage] = useState(false)
  const [isPaidTierActive] = useFeatureFlags([flags.FLAG_PAID_TIER_ACTIVE])
  const { enqueueSnackbar } = useSnackbar()
  const snackbarError = useSnackbarError(enqueueSnackbar)
  const history = useHistory()
  const refetch = useContext(RefetchContext)
  const { user: coach } = useAuth()
  const { pgaHopeCoach: isPgaHopeCoach } = coach?.coach || {}

  useEffect(() => {
    passEventsToState(eventsData, selectedTab)
    const freeTierLimitsReached = get(
      eventsData,
      'currentUser.attributes.freeTierLimitsReached',
      [],
    )
    const isGroupEventsLimitReached = freeTierLimitsReached.includes(
      'GROUP_EVENTS',
    )
    setShouldDisableCreateEvent(
      isPaidTierActive && isGroupEventsLimitReached && !isPgaHopeCoach,
    )
    setShowLimitReachedDisclaimer(isPaidTierActive && isGroupEventsLimitReached)
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsData, showCanceledEvents, selectedTab])

  const currentPage = offset / GROUP_EVENT_PAGE_LIMIT + 1
  const successUrl = `${MY_PGA_COM_HOST}/pga-coach/checkout/success`

  const [
    callCreateSubscriptionPaymentSession,
    { loading: loadingSubscription },
  ] = useCreateSubscriptionPaymentSession()

  const handleUpgradeClick = async () => {
    try {
      const { data } = await callCreateSubscriptionPaymentSession({
        variables: { successUrl },
      })
      const {
        url,
        success,
        message,
      } = data?.createPgaCoachSubscriptionPaymentSession
      if (!success) {
        return snackbarError(message)
      }
      if (success && url) {
        window.open(url, '_blank').focus()
      }
    } catch (e) {
      snackbarError(e?.message)
    }
  }

  const hideInProgressTabIfNoEvents = (selectedTab, events) => {
    if (selectedTab === 'inProgress' && events.length === 0) {
      setSelectedTab('upcoming')
      setShowInProgressTab(false)
    }
  }

  const passEventsToState = (eventsData, selectedTab) => {
    if (eventsData) {
      const inputEvents = get(eventsData, 'currentUser.groupEvents', [])
      const newEvents = parseEvents(inputEvents, selectedTab)
      setEvents(newEvents)
      hideInProgressTabIfNoEvents(selectedTab, newEvents)
      if (inputEvents.length < GROUP_EVENT_PAGE_LIMIT && offset > 0) {
        setLastPage(true)
      }
    }
  }

  const retreatPagination = async () => {
    const newOffset =
      offset - GROUP_EVENT_PAGE_LIMIT < 0 ? 0 : offset - GROUP_EVENT_PAGE_LIMIT
    setOffset(newOffset)
    await refetch({ offset: newOffset })
    setLastPage(false)
  }
  const advancePagination = async () => {
    const newOffset = offset + GROUP_EVENT_PAGE_LIMIT
    setOffset(newOffset)
    await refetch({ offset: newOffset })
  }

  const { canPromoteContent } = eventsData.currentUser.attributes
  const totalEvents = get(eventsData, 'currentUser.groupEvents', []).length

  if (isLoading) return <LinearProgress color="secondary" />
  return (
    <>
      {!canPromoteContent && (
        <Container maxWidth="md">
          <Box mt={4} p={2} borderRadius={4} bgcolor={red[50]}>
            <Typography variant="body2" align="center">
              {
                'You are currently ineligible to promote your events on PGA.com. Please complete '
              }
              <ADMTrainingExternalLink />
              {' to become eligible.'}
            </Typography>
          </Box>
        </Container>
      )}
      <Box mx={[2, 2, 6]} mt={[2, 4]} mb={[3, 3, 0]}>
        {loadingSubscription && <LinearProgress color="secondary" />}
        <DisclaimerWithTwoCta
          icon={<InfoOutlinedIcon style={{ color: '#554427' }} />}
          description="Enjoy the benefits of your free account with up to two active group events. Want to host more? Upgrade now to unlock unlimited group offerings."
          leftCta={
            <Button
              variant="text"
              color="primary"
              onClick={handleUpgradeClick}
              style={{ paddingLeft: 0 }}
            >
              Upgrade Now
            </Button>
          }
          rightCta={
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                setShowLimitReachedDisclaimer(false)
              }}
            >
              Got It
            </Button>
          }
          show={showLimitReachedDisclaimer}
        />
      </Box>
      <EventsDisplaySelection
        events={events}
        totalEvents={totalEvents}
        history={history}
        selectedTab={selectedTab}
        advancePagination={advancePagination}
        retreatPagination={retreatPagination}
        currentPage={currentPage}
        lastPage={lastPage}
      />
    </>
  )
}
export default EventsPageData
