import { gql } from '@apollo/client'

const GET_LESSON_TYPES_AND_COACH_FACILITIES = gql`
  query BookingTypesAndCoachAvailabilities {
    lessonTypes {
      id
      slug
      category
      title
      durationInMinutes
      locations {
        id
        name
      }
      coach {
        externalId
        coachProfile {
          slug
        }
      }
      status
      lessonPacks {
        quantity
        priceInCents
        expirationDuration
      }
      accessCode
      giftingEnabled
    }
    coachAvailabilities {
      id
      coachFacility {
        id
        name
      }
      timeSlots {
        day
        startTime
        endTime
      }
    }
    currentUser {
      attributes {
        ... on Coach {
          freeTierLimitsReached
        }
      }
    }
  }
`

export { GET_LESSON_TYPES_AND_COACH_FACILITIES }
