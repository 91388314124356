import React from 'react'
import { useQuery, gql } from '@apollo/client'
import Dialog from 'components/dialog'
import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import CopyTextButton from 'components/copy-text-button'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import { ReactComponent as GoogleCalendarIcon } from 'images/icons/google-calendar-colored.svg'
import MicrosoftOutlookIcon from 'images/icons/microsoft-outlook.png'
import { API_ROOT } from 'env'
import { gtmEvent } from 'lib/gtm'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const WEBCAL_QUERY = gql`
  query WebcalQuery {
    currentUser {
      externalId
    }
  }
`

const WebcalLinks = () => {
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
  const { data, loading } = useQuery(WEBCAL_QUERY)

  if (loading || !data.currentUser?.externalId) {
    return null
  }

  const calUrl = new URL(
    `/contact/${data.currentUser.externalId}/webcal_feed`,
    API_ROOT,
  )
  const webcalUrl = calUrl.toString().replace('https:', 'webcal:')

  return (
    <Grid container spacing={1} style={{ marginTop: '16px' }}>
      <Grid
        item
        xs={12}
        md={6}
        container
        spacing={1}
        style={{ paddingLeft: isLargeScreen ? '1.5rem' : '.25rem' }}
      >
        <Grid item xs={12}>
          <a
            href={`https://www.google.com/calendar/render?cid=${encodeURIComponent(
              webcalUrl,
            )}`}
            target="_blank"
            style={{ textDecoration: 'none', color: 'inherit' }}
            rel="noreferrer"
            onClick={() =>
              gtmEvent({
                event: 'contact-schedule-subscribe-dialog',
                calendarAction: 'google-calendar',
              })
            }
          >
            <Card variant="outlined" style={{ cursor: 'pointer' }}>
              <Box
                mx={2}
                my={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="subtitle2">Google Calendar</Typography>
                <Box
                  borderRadius={4}
                  bgcolor="#00234B14"
                  width="64px"
                  height="64px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <GoogleCalendarIcon width="50px" height="50px" />
                </Box>
              </Box>
            </Card>
          </a>
        </Grid>
        <Grid item xs={12}>
          <a
            href={`https://outlook.live.com/owa?path=/calendar/action/compose&rru=addsubscription&url=${encodeURIComponent(
              webcalUrl,
            )}&name=PGA%20Coach`}
            target="_blank"
            style={{ textDecoration: 'none', color: 'inherit' }}
            rel="noreferrer"
            onClick={() =>
              gtmEvent({
                event: 'contact-schedule-subscribe-dialog',
                calendarAction: 'outlook-com',
              })
            }
          >
            <Card variant="outlined">
              <Box
                mx={2}
                my={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="subtitle2">Outlook.com</Typography>
                <Box
                  borderRadius={4}
                  bgcolor="#00234B14"
                  width="64px"
                  height="64px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img
                    src={MicrosoftOutlookIcon}
                    width="44px"
                    height="40px"
                    alt="Outlook.com logo"
                  />
                </Box>
              </Box>
            </Card>
          </a>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        container
        spacing={1}
        style={{ paddingRight: isLargeScreen ? '1.5rem' : '.25rem' }}
      >
        <Grid item xs={12}>
          <a
            href={webcalUrl}
            target="_blank"
            style={{ textDecoration: 'none', color: 'inherit' }}
            rel="noreferrer"
            onClick={() =>
              gtmEvent({
                event: 'contact-schedule-subscribe-dialog',
                calendarAction: 'calendar-app',
              })
            }
          >
            <Card variant="outlined">
              <Box
                mx={2}
                my={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="subtitle2">Your Calendar App</Typography>
                <Box
                  borderRadius={4}
                  bgcolor="#00234B14"
                  width="64px"
                  height="64px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CalendarTodayOutlinedIcon color="primary" />
                </Box>
              </Box>
            </Card>
          </a>
        </Grid>
        <Grid item xs={12}>
          <CopyTextButton
            value={calUrl}
            component={Card}
            variant="outlined"
            style={{ cursor: 'pointer' }}
            onClick={() =>
              gtmEvent({
                event: 'contact-schedule-subscribe-dialog',
                calendarAction: 'copy-url',
              })
            }
          >
            <Box
              mx={2}
              my={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="subtitle2">Copy Calendar URL</Typography>
              <Box
                borderRadius={4}
                bgcolor="#00234B14"
                width="64px"
                height="64px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <FileCopyOutlinedIcon color="primary" />
              </Box>
            </Box>
          </CopyTextButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

const SubscribeDialog = ({ trigger }) => {
  return (
    <Dialog trigger={trigger}>
      {({ closeDialog }) => (
        <>
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              Calendar Subscription
              <IconButton onClick={closeDialog}>
                <CloseOutlinedIcon color="primary" />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers={true}>
            <Box p={1} pb={2}>
              <Box mb={2}>
                <Typography variant="body1" gutterBottom>
                  Export your PGA events into your personal calendar
                </Typography>
              </Box>
              <WebcalLinks />
            </Box>
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}

export default SubscribeDialog
