import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { get, sortBy } from 'lodash'
import { DateTime } from 'luxon'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { DateCalendar } from '@mui/x-date-pickers'
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import CustomLuxonAdapter from 'lib/CustomLuxonAdapter'
import { aggregateByDate, aggregateByPeriod, dateRangeForMonth } from './utils'
import { useStyles, TimeSlotButton } from './styles'
import { gtmEvent } from 'lib/gtm'
import { useIsMobile } from 'lib/hooks'
import { toThreeLetterTimezone } from 'utils/timezones'
import NoTimesAvailable from './no-times-available'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { defaultFont } from 'themes'

const v5Theme = createTheme({
  palette: {
    primary: {
      main: '#043362',
    },
  },
  typography: {
    fontFamily: defaultFont,
    fontSize: 16,
  },
})

const TimeSlotSelectStep = ({
  data,
  coach,
  selectedDate = DateTime.now(),
  setSelectedDate,
  currentUrl,
  refetch,
  setShowRequestTime,
  onMonthChange,
  isDataLoading = false,
  eventObject = {},
  nextExpirationDate,
}) => {
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(DateTime.local())
  const timezone = get(data, ['0', 'facilities', '0', 'timezone'])
  const spaceAvailableTimes = get(data, [
    '0',
    'practiceSessionTypes',
    '0',
    'availableTimes',
  ])
  const isBayReservation = !!spaceAvailableTimes
  const searchQuery = isBayReservation ? '?startDateTime=' : '&startDateTime='
  const timeSlots = isBayReservation
    ? sortBy(spaceAvailableTimes)
    : sortBy(get(data, ['0', 'availableTimes']).map(t => t.startDateTime))
  const nextSlot = timeSlots[0]

  const filteredTimeSlots = nextExpirationDate
    ? timeSlots.filter(ts => {
      const slotDate = DateTime.fromISO(ts)
      const expirationDate = DateTime.fromISO(nextExpirationDate)
      return slotDate <= expirationDate
    })
    : timeSlots

  const timeSlotsByDate = aggregateByDate(filteredTimeSlots, timezone)
  const formattedTimezone = toThreeLetterTimezone(timezone, selectedDate)
  const showTimezone = formattedTimezone !== null

  const timeSlotsForSelectedDate =
    selectedDate != null && selectedDate.toISODate() in timeSlotsByDate
      ? timeSlotsByDate[selectedDate.toISODate()]
      : []
  const timeSlotsForSelectedDateByPeriod = aggregateByPeriod(
    timeSlotsForSelectedDate,
    timezone,
  )
  const isMobile = useIsMobile()
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)

  const handleMonthChange = async month => {
    const { startDateTime, endDateTime } = dateRangeForMonth(
      month,
      DateTime.local(),
    )
    setIsLoading(true)
    await refetch({ startDateTime, endDateTime })
    setIsLoading(false)
    setSelectedDate(startDateTime)
  }

  const handleTimeSlotClick = ts => {
    setSelectedTimeSlot(ts)
    const selectedTime = {
      event_time: DateTime.fromISO(ts, {
        zone: timezone,
      }).toFormat('hh:mm a'),
      event_date: DateTime.fromISO(ts, {
        zone: timezone,
      }).toFormat('yyyy-LL-dd'),
    }

    // select-booking-time
    gtmEvent({
      ...eventObject,
      ...selectedTime,
    })
    // Remove the below event in the future.
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'book-session',
      formAction: 'selected-booking-time',
    })
  }

  return (
    <>
      <Grid item xs={12} md={5} className={classes.timeSlotSelect}>
        <Grid
          container
          item
          xs={12}
          justifyContent={isMobile ? 'center' : null}
        >
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              className={classes.heading}
              align={isMobile ? 'center' : 'left'}
            >
              {selectedDate.toLocaleString(DateTime.DATE_HUGE)}
            </Typography>
          </Grid>
          <Grid item>
            <Paper elevation={0} className={classes.datePickerStyles}>
              <ThemeProvider theme={v5Theme}>
                <LocalizationProvider dateAdapter={CustomLuxonAdapter}>
                  <DateCalendar
                    disablePast
                    views={['day']}
                    value={selectedDate}
                    onChange={setSelectedDate}
                    onMonthChange={onMonthChange || handleMonthChange}
                    shouldDisableDate={day => {
                      return (
                        !(day.toISODate() in timeSlotsByDate) &&
                        day.toISODate() !== selectedDate.toISODate()
                      )
                    }}
                    dayOfWeekFormatter={day => `${day}`}
                    loading={isLoading || isDataLoading}
                    renderLoading={() => <DayCalendarSkeleton />}
                  />
                </LocalizationProvider>
              </ThemeProvider>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={7} className={classes.availableTimesWrapped}>
        <Box>
          {timeSlotsForSelectedDate.length > 0 ? (
            ['AM', 'PM'].map(pd => (
              <Box key={pd} mb={2}>
                {timeSlotsForSelectedDateByPeriod[pd].length > 0 && (
                  <Typography variant="body1" className={classes.periodHeading}>
                    {showTimezone ? `${pd} (${formattedTimezone})` : pd}
                  </Typography>
                )}
                <Box display="flex" flexWrap="wrap">
                  {timeSlotsForSelectedDateByPeriod[pd].map(ts => {
                    const isSelected = ts === selectedTimeSlot
                    const dateTime = DateTime.fromISO(ts, {
                      zone: timezone,
                    }).toFormat('hh:mm a')
                    const practiceSessionTypeId = isBayReservation
                      ? get(data, ['0', 'practiceSessionTypes', '0', 'id'])
                      : ''
                    const urlWithPracticeSessionTypeId = practiceSessionTypeId
                      ? `&practiceSessionTypeId=${practiceSessionTypeId}`
                      : ''
                    return (
                      <Link
                        key={ts}
                        to={`${currentUrl}${searchQuery}${ts}${urlWithPracticeSessionTypeId}`}
                        style={{ textDecoration: 'none' }}
                      >
                        <TimeSlotButton
                          key={ts}
                          onClick={() => {
                            handleTimeSlotClick(ts)
                          }}
                          variant={isSelected ? 'contained' : 'outlined'}
                          color="primary"
                          data-testid="time-slot-button"
                        >
                          {dateTime}
                        </TimeSlotButton>
                      </Link>
                    )
                  })}
                </Box>
              </Box>
            ))
          ) : (
            <NoTimesAvailable
              nextSlot={nextSlot}
              timezone={timezone}
              showTimezone={showTimezone}
              classes={classes}
              coach={coach}
              setShowRequestTime={setShowRequestTime}
              isBayReservation={isBayReservation}
              setSelectedDate={setSelectedDate}
            />
          )}
        </Box>
      </Grid>
    </>
  )
}

export default TimeSlotSelectStep

TimeSlotSelectStep.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      availableTimes: PropTypes.arrayOf(
        PropTypes.shape({
          startDateTime: PropTypes.string.isRequired,
          locationId: PropTypes.string,
          coaches: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string.isRequired,
            }),
          ),
        }),
      ),
    }),
  ),
}
