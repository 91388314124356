import { styled, makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import blue from '@material-ui/core/colors/blue'

// const lightGrey = '#f5f5f5'
const lightGrey = '#d6d6d6'
const breakMobile = 800
const breakTiny = 500

const useStyles = makeStyles(theme => ({
  root: {
    // form input
    '& .text-input:not(.last-input)': {
      marginBottom: theme.spacing(4),
    },
    '& .text-input.last-input-mobile': {
      [theme.breakpoints.down(breakMobile)]: {
        marginBottom: 0,
      },
    },
    // hr dividers on the page
    '& hr': {
      borderTop: `1px solid ${lightGrey}`,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    // h5 headers outside of cards
    '& .checkbox': {
      height: '24px',
      padding: 0,
    },
  },
  // moves nav links above sections to reveal content under the top nav
  idLink: {
    position: 'relative',
    top: -theme.spacing(8),
  },
  card: {
    marginBottom: theme.spacing(3),
  },
  header: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
  },
  content: {
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    [theme.breakpoints.only('xs')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  // generic containers
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    '& a': {
      textDecoration: 'none',
    },

    [theme.breakpoints.down(breakMobile)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',

      '& button': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  formWrapper: {
    display: 'inline-flex',
    flexDirection: 'column',
    width: 'calc(100% - 248px)',
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(8),

    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 36px)',
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: 0,
    },
  },
  profileTitle: {
    fontWeight: 600,
    color: 'black',
  },
  navAside: {
    display: 'inline-flex',
    width: '148px',
    position: 'sticky',
    top: '260px',
    paddingBottom: '150px',

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },

    '& #coachNavWrapper': {
      display: 'flex',
      flexDirection: 'column',
      width: '248px',
      marginTop: theme.spacing(2),

      [theme.breakpoints.down('md')]: {
        width: '200px',
      },
    },

    '& a': {
      padding: theme.spacing(2),
      borderRadius: '4px',
      fontSize: '14px',
      lineHeight: '1',
      textTransform: 'uppercase',
      fontWeight: '600',
      color: theme.palette.primary.main,
      textDecoration: 'none',

      '&:hover': {
        color: 'black',
        backgroundColor: lightGrey,
      },
    },
  },
  addableSectionWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    border: `solid 1px ${lightGrey}`,
    borderLeft: `4px solid ${theme.palette.primary.main}`,

    '& .inputDeleteWrapper': {
      display: 'flex',
    },
  },
  innerAddable: {
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
    marginRight: theme.spacing(2),
  },
  photosImagesWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      '& div': {
        marginRight: '0',
      },
    },
    '& checkbox': {
      marginLeft: '-4px',
    },
  },
  icon: {
    marginLeft: '8px',
    color: theme.palette.grey[600],
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      right: '10px',
      top: '4px',
    },
  },
  facilityIcon: {
    fontSize: '1.5rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.125rem',
    },
  },
  coachAvatar: {
    width: '78px',
    height: '78px',
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(0),
    },
  },
  facilityIconButton: {
    backgroundColor: '#F4F6F8',
    marginRight: theme.spacing(2),
    minWidth: '48px',
    height: '48px',
  },
  chip: {
    backgroundColor: '#51657F',
    '& svg': {
      backgroundColor: 'inherit !important',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '4px',
    },
  },
  facilityWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    flex: 'auto',
    paddingRight: theme.spacing(2),
  },
  description: {
    '& a': {
      color: theme.palette.blue.main,
      textDecoration: 'underline',
    },
  },
  facilityDetailsIcon: {
    width: '48px',
    height: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '16px',
  },
}))

export const DialogBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '4rem 0',

  [theme.breakpoints.up(700)]: {
    width: '600px',
  },

  '& a': {
    textAlign: 'center',

    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
}))

export const FlexRow = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}))

export const FlexColumn = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const HelperMsg = styled(Typography)(({ theme }) => ({
  lineHeight: 1,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  color: '#9e9e9e',
}))

export const ErrorMsg = styled(Typography)(({ theme }) => ({
  lineHeight: 1,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  color: theme.palette.error.main,
}))

export const SectionTitle = styled(Typography)(({ theme }) => ({
  color: 'black',
  fontWeight: 600,
  marginBottom: theme.spacing(1),
}))

export const CardRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `0 ${theme.spacing(5)}`,
  color: theme.palette.text.primary,

  [theme.breakpoints.down(breakTiny)]: {
    padding: theme.spacing(0),
  },

  // prevent error messages from disrupting the height of inputs
  '& .text-input:not(.multiline)': {
    '& p': {
      position: 'absolute',
      top: theme.spacing(7),
    },
    '& .input-adornment p': {
      position: 'unset',
    },
  },
}))

export const InputsTwoColumns = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',

  '& .text-input': {
    display: 'inline-flex',
    width: '100%',

    [theme.breakpoints.down(breakMobile)]: {
      width: '100%',
    },
  },
}))

export const FlexInputWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down(breakMobile)]: {
    flexDirection: 'column',
  },

  '& .text-input:not(:last-child)': {
    [theme.breakpoints.up(breakMobile)]: {
      marginRight: theme.spacing(4),
    },
  },
  '& .text-input:nth-child(1)': {
    flex: '5',
  },
  '& .text-input:nth-child(2)': {
    flex: '2',
  },
  '& .text-input:nth-child(3)': {
    flex: '1',
  },
  '& div:last-child': {
    flex: '2',
  },

  '&.wrap3 .text-input:nth-child(2)': {
    flex: '1',
  },
  '&.wrap3 .text-input:nth-child(3)': {
    flex: '2',
  },

  '&.wrap2 .text-input:nth-child(1)': {
    flex: '2',
  },
  '&.wrap2 .text-input:nth-child(2)': {
    flex: '8',
  },
}))

export const WebAddressLink = styled(Typography)(({ theme }) => ({
  padding: '8px 8px ',
  borderRadius: '9px',
  lineHeight: 1,
  color: 'black',
  transition: 'background-color 0.5s',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  position: 'relative',
  width: 'fit-content',
  '& span': {
    whiteSpace: 'nowrap',
    maxWidth: 'calc(100% - 24px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: 'rgba(0, 35, 75, 0.08)',
  },
  [theme.breakpoints.down('xs')]: {
    backgroundColor: blue[50],
    fontSize: '14px',
    fontWeight: 400,
    justifyContent: 'start',
    width: 'calc(100% - 24px)',
  },
}))

export const ProfilePhoto = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(4),

  [theme.breakpoints.down(700)]: {
    flexDirection: 'column',
  },
}))

export const ProfileCircle = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '78px',
  height: '78px',
  marginRight: theme.spacing(4),
  borderRadius: '100%',
  backgroundColor: theme.palette.secondary.main,
  justifyContent: 'center',
  alignItems: 'center',

  '& img': {
    width: '78px',
    height: '78px',
    borderRadius: '100%',
    objectFit: 'cover',
    objectPosition: 'top',
  },

  [theme.breakpoints.down(breakMobile)]: {
    marginBottom: theme.spacing(2),
  },

  '& h1': {
    fontSize: '30px',
    fontWeight: 600,
    color: theme.palette.getContrastText(theme.palette.secondary.light),
  },
}))

export const PhotoSelector = styled('div')(({ isSelected }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 8px 0',
  margin: '0 1.5rem 1.5rem 0',
  border: `1pt solid ${isSelected ? '#AB9157' : '#d6d6d6'}`,
}))

export const PhotoSelectorPhoto = styled('div')(({ src }) => ({
  height: '220px',
  width: '220px',
  border: '1px solid #d6d6d6',
  backgroundImage: `url(${src})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
}))

export const SlugWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '60%',
  color: 'black',
  margin: '16px 0',
  [theme.breakpoints.down(breakMobile)]: {
    width: '100%',
    marginBottom: theme.spacing(4),
  },

  '& .text-input': {
    marginBottom: 0,

    '& .input-adornment': {
      marginRight: 0,

      '& p': {
        top: '0',
      },
    },
  },
}))

export const BioWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  minHeight: '200px',
  border: '1px solid #E0E0E0',
  borderRadius: '4px',
  padding: '0 16px',
  marginTop: '24px',
  marginBottom: '16px',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}))

export const TestimonyFields = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 'auto',
  marginRight: theme.spacing(2),
}))

export const FacilityImage = styled('div')(() => ({
  maxHeight: '300px',

  '& img': {
    maxHeight: '300px',
  },
}))

export const CheckboxItemWrapper = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'top',
  width: '100%',
  marginTop: theme.spacing(2),

  [theme.breakpoints.down(breakMobile)]: {
    width: '100%',
  },

  '& .checkbox': {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  '& label > span:not(.checkbox)': {
    color: 'black',
    lineHeight: '1.5',
  },
}))

export const SuggestedSlugContainer = styled(Box)(({ theme }) => ({
  padding: '20px 0',
  '& .innerContainer': {
    border: '1px solid #d6d6d6',
    display: 'flex',
    padding: '10px 30px',
    borderRadius: '5px',
    flexDirection: 'row',
    [theme.breakpoints.down(breakMobile)]: {
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  '& .suggestedItem': {
    flexDirection: 'column',
    padding: '0 20px 0 0',
    [theme.breakpoints.down(breakMobile)]: {
      padding: '1em',
    },
  },
}))

export const ProfileExamples = styled('ul')(({ theme }) => ({
  display: 'flex',
  fontSize: '.7em',
  flexDirection: 'row',
  justifyContent: 'space-between',
  listStyleType: 'none',
  margin: 0,
  padding: 0,
  width: '100%',
  [theme.breakpoints.down(breakMobile)]: {
    alignItems: 'center',
    flexDirection: 'column',
    fontSize: '1em',
    textAlign: 'left',
  },
  '& .highlight': {
    color: theme.palette.secondary.main,
    fontWeight: 600,
  },
  '& li a': {
    textDecoration: 'none',
    color: '#000',
  },
}))

export default useStyles
