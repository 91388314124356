import { gql } from '@apollo/client'

export const GetCoachesQuery = gql`
  query ConsumerCoachAndRepresentatives {
    currentUser {
      attributes {
        ... on Contact {
          lessonPacks {
            id
            totalCredits
            remainingCredits
            expiresAt
            lessonType {
              id
              title
              locations {
                id
              }
              coach {
                id
                bookingUrl
                name
              }
              giftingEnabled
            }
          }
        }
      }
      representatives {
        relationshipType
        student {
          id
          firstName
          lastName
          coaches {
            id
            externalId
            name
            coachProfile {
              id
              slug
              profilePhoto
              facilities {
                facilityName
              }
            }
          }
        }
      }
    }
  }
`
