import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import InputAdornment from '@material-ui/core/InputAdornment'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { CLOUDINARY_CLOUDNAME, CLOUDINARY_UPLOAD_PRESET } from 'env'
import { IconInfo } from 'components/atoms/icons'
import usStates from 'utils/usStates'
import secureLink from 'utils/secureLink'
import { gendersList } from '../constants'
import useStyles, {
  BioWrapper,
  CardRoot,
  ErrorMsg,
  FlexInputWrapper,
} from '../profile.styles'
import Price from './Price'
import { handleImageUpload } from 'lib/cloudinary/uploadImage'
import { getInitials } from 'lib/text-display'
import useIsMobile from 'lib/hooks/use-is-mobile'
import { useFormContext, Controller } from 'react-hook-form'
import FormControl from '@material-ui/core/FormControl'
import Social from './social'
import { markdownToDraft } from 'markdown-draft-js'
import RichTextCounter from 'components/rich-text-counter'

const uploadOptions = {
  cloudName: CLOUDINARY_CLOUDNAME,
  upload_preset: CLOUDINARY_UPLOAD_PRESET,
  cropping: true,
  sources: ['local', 'camera'],
  multiple: false,
  croppingAspectRatio: 1,
  resourceType: 'image',
  showSkipCropButton: false,
  folder: 'dot-com-images',
  showPoweredBy: false,
  clientAllowedFormats: ['png', 'jpeg', 'jpg', 'gif', 'webp'],
}

const profileImgMsg = 'Recommended dimensions of 400x400'

// todo take out to common, move inner components to the separate profile folder
const idSectionConfig = [
  {
    name: 'firstName',
    label: 'First Name',
    required: true,
  },
  {
    name: 'lastName',
    label: 'Last Name',
    required: true,
  },
  {
    name: 'displayName',
    label: 'Display Name',
  },
  {
    name: 'gender',
    label: 'Gender',
    menuList: gendersList,
  },
  {
    name: 'title',
    label: 'Title',
  },
]

const contactSectionConfig = [
  {
    name: 'email',
    label: 'Email',
    required: true,
    type: 'text',
  },
  {
    name: 'phoneNumber',
    label: 'Phone',
    required: false,
    type: 'text',
  },
  {
    name: 'phoneExt',
    label: 'Ext.',
    required: false,
    type: 'text',
  },
  {
    name: 'mobileNumber',
    label: 'Mobile',
    required: false,
    type: 'text',
  },
]

const Profile = ({ formFields }) => {
  const [uploadError, setUploadError] = useState(null)
  const { register, control, setValue, getValues, errors, watch } = useFormContext()
  const { firstName, lastName } = getValues()
  const profilePhoto = watch('profilePhoto')
  const initials = getInitials(`${firstName} ${lastName}`)
  const classes = useStyles()
  const isMobile = useIsMobile()

  const coachBio = getValues('bio')
  const defaultCoachBio = coachBio ? JSON.stringify(markdownToDraft(coachBio))
    : JSON.stringify(markdownToDraft(control.defaultValuesRef.current.bio))

  return (
    <div>
      <CardRoot>
        {/* About You Section */}
        <Typography variant="subtitle1" gutterBottom>About You</Typography>
        <Grid container spacing={2}>
          {idSectionConfig.map(
            ({ name, label, menuList, required }) => (
              <Grid item xs={12} md={6} key={name}>
                {menuList ? (
                  <FormControl
                    key={name}
                    error={!!errors[name]}
                    className={`text-input ${errors[name] && ' error'}`}
                  >
                    <Controller
                      name={name}
                      control={control}
                      render={({ value, onChange }) => (
                        <TextField
                          select
                          variant="standard"
                          name={name}
                          label={label}
                          value={value || ''}
                          error={!!errors[name]}
                          helperText={errors[name]?.message}
                          required={required}
                          onChange={onChange}
                        >
                          {menuList.map(opt => (
                            <MenuItem key={opt.value} value={opt.value}>
                              {opt.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </FormControl>
                ) : (
                  <TextField
                    key={name}
                    name={name}
                    label={label}
                    type="text"
                    inputRef={register()}
                    variant="standard"
                    className={`text-input ${errors[name] && ' error'}`}
                    error={!!errors[name]}
                    helperText={errors[name]?.message}
                    required={required}
                  />
                )}
              </Grid>
            ),
          )}
        </Grid>
        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center" mb={6}>
          <Avatar
            className={classes.coachAvatar}
            alt={`${firstName} ${lastName}`}
            src={secureLink(profilePhoto)}
          >
            {initials}
          </Avatar>
          <input type="hidden" name="profilePhoto" ref={register()} />
          <Box display="flex" flexDirection="column" >
            <Box display="flex" justifyContent="space-around">
              <Button
                variant="outlined"
                color="primary"
                type="button"
                onClick={() =>
                  handleImageUpload(
                    uploadOptions,
                    setValue,
                    setUploadError,
                    'profilePhoto',
                  )
                }
              >
                Upload{!profilePhoto ? ' New' : ''} Picture
              </Button>
              {profilePhoto && (
                <Button
                  variant="text"
                  type="button"
                  onClick={() => {
                    setValue('profilePhoto', null)
                  }}
                >
                  Delete
                </Button>
              )}
            </Box>
            <Box mt={2}>
              {uploadError ? (
              <ErrorMsg style={{ marginBottom: 0 }}>{uploadError}</ErrorMsg>
              ) : (
              <Typography variant="body2" color="textSecondary">{profileImgMsg}</Typography>
              )}
            </Box>
          </Box>
        </Box>
      </CardRoot>
      <Box mt={2}>
        {/* Contact Section */}
        <Typography variant="subtitle1" gutterBottom>Contact</Typography>
        <FlexInputWrapper>
          {contactSectionConfig.map(({ name, label, required, type }) => (
            <TextField
              key={name}
              name={name}
              label={label}
              type={type}
              inputRef={register()}
              variant="standard"
              className={`text-input ${errors[name] && ' error'}`}
              error={!!errors[name]}
              helperText={errors[name]?.message}
              required={required}
            />
          ))}
        </FlexInputWrapper>
      </Box>
      <CardRoot>
        {/* Location Section */}
        <Typography variant="subtitle1" >Service Area</Typography>
        <Box mb={2}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            This describes where you offer services on PGA.com and helps people find you on Google.
          </Typography>
        </Box>
        <FlexInputWrapper className="only3">
          <TextField
            key="city"
            name="city"
            label="City"
            type="text"
            inputRef={register()}
            variant="standard"
            className={`text-input ${errors.city && ' error'}`}
            error={!!errors.city}
            helperText={errors.city?.message}
          />
          <FormControl
            error={!!errors.state}
            className={`text-input ${errors.state && ' error'}`}
          >
            <Controller
              name="state"
              control={control}
              render={({ value, onChange }) => (
                <Autocomplete
                  name="state"
                  id="state"
                  disableClearable
                  value={value || ''}
                  options={usStates}
                  onChange={(e, value) => onChange(value)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="State"
                      variant="standard"
                      error={!!errors.state}
                      helperText={errors?.state?.message}
                    />
                  )}
                />
              )}
            />
          </FormControl>
          <TextField
            key="zipCode"
            name="zipCode"
            label="Zip"
            type="text"
            inputRef={register()}
            variant="standard"
            className={`text-input last-input-mobile ${errors.zipCode && ' error'}`}
            error={!!errors.zipCode}
            helperText={errors.zipCode?.message}
          />
          <div />
        </FlexInputWrapper>
      </CardRoot>
      <Price />
      <Box mt={3}>
        {/* Personal Section */}
        <Typography variant="subtitle1">Personal</Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Use your bio to introduce yourself, your golfing journey, and why students should choose you as their coach. Show your unique personality.
        </Typography>
        <BioWrapper>
          <Controller
            name="bio"
            control={control}
            defaultValue={null}
            render={({ onChange, value }, _) => (
              <RichTextCounter
                label="Bio"
                controls={['bold', 'italic', 'link', 'bulletList']}
                characterLimit={5000}
                defaultValue={defaultCoachBio}
                onChange={onChange}
                fieldName="bio"
                helperText={errors.bio?.message}
              />
            )}
          />
        </BioWrapper>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box py={2}>
              <TextField
                name="numberOfStudents"
                label="Number of Students"
                type="number"
                variant="standard"
                inputRef={register()}
                className={`text-input ${errors?.numberOfStudents && ' error'}`}
                error={!!errors.numberOfStudents}
                helperText={errors?.numberOfStudents?.message}
                inputProps={{
                  pattern: '/d+',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box py={2}>
              <TextField
                name="numberOfFollowers"
                label="Number of social media followers"
                type="number"
                variant="standard"
                inputRef={register()}
                className={`text-input ${errors?.numberOfFollowers && ' error'}`}
                error={!!errors.numberOfFollowers}
                helperText={errors?.numberOfFollowers?.message}
                inputProps={{
                  pattern: '/d+',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="Highest social media following on one account"
                        aria-label="Highest social media following on one account"
                      >
                        <IconButton style={{ padding: 0 }}>
                          <IconInfo />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Social formFields={formFields} />
      </Box>
    </div>
  )
}

export default Profile
