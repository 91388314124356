import useStyles from './styles'
import Button from '@material-ui/core/Button'

/*
 * CoachInsightsCTACard Component
 *
 * This component will display a card for a coach to take action on.
 *
 * Props:
 * - icon: A React component to render as the icon.
 * - description: A brief description or label for the value.
 * - buttonText: The text to display on the button.
 * - buttonOnClick: The function to call when the button is clicked.
 */
const CoachInsightsCTACard = ({
  icon: Icon,
  value,
  description,
  avatarBackground,
  iconColor,
  valueTestId,
  buttonText,
  buttonOnClick,
  ...props
}) => {
  const classes = useStyles()

  return (
    <div
      data-testid={valueTestId}
      data-type="cta-card"
      className={classes.card}
    >
      <div className={classes.cardContent}>
        {Icon ? (
          <div
            className={classes.avatar}
            style={{ backgroundColor: avatarBackground }}
          >
            <Icon className={classes.icon} style={{ color: iconColor }} />
          </div>
        ) : (
          <div />
        )}
        <div className={classes.valueContainer}>
          <p className={classes.description}>{description}</p>
          <Button variant="outlined" color="primary" onClick={buttonOnClick}>
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CoachInsightsCTACard
